/* Tooltip CSS */
.topTooltip:before {
    left: 50%;
    top: -35px;
    z-index: 999;
    bottom: auto;
    color: white;
    font-size: 12px;
    padding: 6px 8px;
    font-weight: 600;
    position: absolute;
    border-radius: 2px;
    letter-spacing: 0.05rem;
    background: #616161e6;
    content: attr(datatooltip);
    transform-origin: bottom center;
    font-family: Roboto, sans-serif;
    transform: translateX(-50%) scale(0);
    transition: transform 0.2s ease-in-out;
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
}

.downTooltip:before {
    left: 50%;
    z-index: 999;
    bottom: -35px;
    color: white;
    font-size: 12px;
    padding: 6px 8px;
    font-weight: 600;
    position: absolute;
    border-radius: 2px;
    transform-origin: top;
    letter-spacing: 0.05rem;
    background: #616161e6;
    content: attr(datatooltip);
    font-family: Roboto, sans-serif;
    transform: translateX(-50%) scale(0);
    transition: transform 0.2s ease-in-out;
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
}

.leftTooltip:before {
    top: 50%;
    left: -15px;
    right: auto;
    bottom: auto;
    z-index: 999;
    color: white;
    font-size: 12px;
    padding: 6px 8px;
    font-weight: 600;
    position: absolute;
    border-radius: 2px;
    letter-spacing: 0.05rem;
    background: #616161e6;
    content: attr(datatooltip);
    transform-origin: right center;
    font-family: Roboto, sans-serif;
    transition: transform 0.2s ease-in-out;
    transform: translate(-100%, -50%) scale(0);
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
}

.rightTooltip:before {
    top: 50%;
    left: auto;
    right: -10px;
    bottom: auto;
    z-index: 999;
    color: white;
    font-size: 12px;
    padding: 6px 8px;
    font-weight: 600;
    position: absolute;
    border-radius: 2px;
    letter-spacing: 0.05rem;
    background: #616161e6;
    content: attr(datatooltip);
    transform-origin: left center;
    font-family: Roboto, sans-serif;
    transition: transform 0.2s ease-in-out;
    transform: translate(100%, -50%) scale(0);
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
}


.topTooltip:hover:before, 
.downTooltip:hover:before {
    transform: translateX(-50%) scale(1);
}
.leftTooltip:hover:before {
    transform: translate(-100%, -50%) scale(1);
}
.rightTooltip:hover:before {
    transform: translate(100%, -50%) scale(1);
}