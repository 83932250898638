.clock-container {
    position: relative;
    min-height: 300px;
}

.clock {
    width: 300px;
    height: 300px;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 25%;
    transform: translate(-50%, -50%);
    box-shadow: 0 2px 30px rgba(0, 0, 0, 0.2);
    font-size: 24px;
    color: #444;
    z-index: 9999;
    text-align: center;
    background-color: #eee;
}

.clock::after {
    background: #aaa;
    content: "";
    width: 12px;
    height: 12px;
    border-radius: 50%;
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 2px solid #fff;
}

.clock .hour_hand {
    position: absolute;
    width: 6px;
    height: 60px;
    background: #222;
    top: 30%;
    left: 49%;
    transform-origin: bottom;
}

.clock .min_hand {
    position: absolute;
    width: 4px;
    height: 80px;
    background: #444;
    top: 22.5%;
    left: 49%;
    transform-origin: bottom;
}

.clock .sec_hand {
    position: absolute;
    width: 2px;
    height: 118px;
    background: red;
    top: 10.5%;
    left: 50%;
    transform-origin: bottom;
}

.clock span {
    position: absolute;
    font-family: "Source Sans Pro", sans-serif;
    font-weight: 700;
}

.clock .twelve {
    top: 10px;
    left: 46%;
}

.clock .one {
    top: 10%;
    right: 26%;
}

.clock .eleven {
    top: 10%;
    left: 26%;
}

.clock .two {
    top: 25%;
    right: 10%;
}

.clock .three {
    right: 10px;
    top: 46%;
}

.clock .four {
    right: 30px;
    top: 67%;
}

.clock .five {
    right: 78px;
    top: 80%;
}

.clock .six {
    bottom: 10px;
    left: 50%;
}

.clock .seven {
    left: 80px;
    top: 82%;
}

.clock .eight {
    left: 30px;
    top: 67%;
}

.clock .nine {
    left: 10px;
    top: 46%;
}

.clock .ten {
    top: 25%;
    left: 10%;
}