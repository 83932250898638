.backdrop_parent{
    padding: 0.5rem;
    position: relative;
    word-wrap: break-word;
    word-break: break-word;
    border-radius: 0.25rem;
    border: 1px solid #dee2e6;
    background-color: rgb(239 239 239 / 66%);
}

.backdrop_parent:hover{
    background-color: rgb(233 235 239 / 99%);
}

.backdrop{
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    position: absolute;
}