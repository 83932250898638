.Menu {
    margin: 0;
    z-index: 1000;
    display: block;
    font-size: 1rem;
    min-width: 15rem;
    text-align: left;
    color: #212529;
    list-style: none;
    overflow-y: auto;
    padding: 0.5rem 0;
    max-height: 400px;
    position: absolute;
    border-radius: 0.375rem;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgb(0, 0, 0, 0.175);
}

.MenuItem {
    border: 0;
    width: 100%;
    clear: both;
    display: block;
    cursor: pointer;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    white-space: nowrap;
    letter-spacing: 1px;
    transition: 0.3s all;
    padding: 0.25rem 1rem;
    text-decoration: none;
    background-color: transparent;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}

.MenuItem:hover {
    font-weight: 600;
    font-size: 1.25rem;
    background-color: rgb(108, 117, 125, 0.1);
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.MenuNoItem {
    display: block;
    font-weight: 400;
    color: #212529;
    line-height: 0.75rem;
    white-space: pre-wrap;
    font-family: monospace;
    padding: 0.25rem 0.5rem;
    background-color: transparent;
}