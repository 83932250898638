.GroupHead {
    gap: 0.5rem;
    display: flex;
    padding: 0.5rem;
    background-color: rgb(108, 117, 125, 0.1);
}

.GroupBody {
    gap: 0.5rem;
    display: flex;
    padding: 0.5rem;
    flex-direction: column;
}