body.dark,
body.dark .header,
body.dark .table,
body.dark .page-footer,
body.dark .page-sidebar,
body.dark span.user-name,
body.dark span.user-role,
body.dark .header .page-brand,
body.dark .react-flow__node-conditionNode,
body.dark li.dropdown-user>.dropdown-menu,
body.dark li.dropdown-user>.dropdown-menu .dropdown-item {
	background-color: #333;
	color: #fff !important;
}

body.dark .ibox, body.dark .ibox-grey .ibox-head {
	background-color: #333333eb;
	color: #fff;
	border: 1px solid #fff;
	border-radius: 2px;
	/* margin-bottom: 0; */
}

body.dark .header {
	border-bottom: 1px solid #fff;
}

body.dark .page-footer {
	border-top: 1px solid #fff;
}

body.dark .page-sidebar {
	border-right: 1px solid #fff;
}

body.dark .modal-content {
	border: 1px solid #eee;
}

body.dark .radio-list,
body.dark .form-checkbox,
body.dark .check-list label,
body.dark .contenteditable pre,
body.dark .nav-tabs .nav-link,
body.dark .contenteditable .copy-to-clipboard {
	color: #000;
}

body.dark .sheet,
body.dark .content form,
body.dark .modal-content,
body.dark .side-menu>li.active {
	background-color: #333;
}

body.dark .pagination .page-link {
	color: #0d6efd;
}

body.dark .content-wrapper {
	background-color: #333333eb;
	color: #fff;
}

body.dark .dropdown-divider {
	background-color: #fff;
}

body.dark .table-striped>tbody>tr:nth-of-type(odd) {
	color: #fff;
}

body.dark .preloader-backdrop {
	background-color: #414548;
}

body.dark .preloader-backdrop .page-preloader::before {
	border-top: 2px solid #fff;
}

body.dark #loginForm .form-checkbox {
	color: #fff;
}