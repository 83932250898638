.timeline {
    border-left: 1px solid hsl(0, 0%, 90%);
    position: relative;
    list-style: none;
}

.timeline .timeline-item {
    position: relative;
    margin-bottom: 20px;
}

.timeline .timeline-item:last-child {
    margin-bottom: 0;
}

.timeline .timeline-item:after {
    position: absolute;
    display: block;
    top: 0;
    background-color: hsl(0, 0%, 90%);
    left: -34px;
    border-radius: 50%;
    height: 11px;
    width: 11px;
    content: "";
}

.timeline .timeline-item.active:after, .timeline .timeline-item:hover:after {
    background-color: #5bc0de;
}

.timeline .timeline-title {
    margin: 10px 0;
    cursor: pointer;
    font-size: 15px;
}

.timeline .timeline-title.open {
    background-color: hsl(0, 0%, 90%);
    padding: 10px;
}

.timeline .timeline-title .copy-span {
    display: none;
}

.timeline .timeline-title.open .copy-span {
    display: block;
}

.timeline .timeline-title.open .copy-span:hover {
    background-color: #fff;
    padding: 4px;
    margin-top: -4px;
}

.timeline .timeline-icon {
    position: absolute;
    left: -48px;
    background-color: hsl(217, 88.2%, 90%);
    color: hsl(217, 88.8%, 35.1%);
    border-radius: 50%;
    height: 31px;
    width: 31px;
    display: flex;
    align-items: center;
    justify-content: center;
    display: none;
}