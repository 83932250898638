.tagsContainer {
    padding: 5px 10px;
    background-color: #eee;
    border-radius: 2px;
    border: 1px solid #aaa;
}

.tagsContainer .badge {
    margin-top: 5px;
    cursor: pointer;
}