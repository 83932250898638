.Completed {
    height: 75%;
    display: flex;
    min-width: 0px;
    cursor: pointer;
    user-select: none;
    width: min-content;
    border-radius: 3px;
    box-sizing: border-box;
    padding: 2px 1px 2px 4px;
    justify-content: space-between;
    background-color: rgb(230, 230, 230);
}

.Content {
    font-size: 10px;
    font-weight: 700;
    align-self: center;
    letter-spacing: 0.75px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.ContentClose {
    align-items: center;
    border-radius: 2px;
    display: flex;
    padding-left: 4px;
    padding-right: 4px;
    margin-left: 0.25rem;
    box-sizing: border-box;
}

.ContentClose:hover {
    background-color: rgb(255, 189, 173);
    color: rgb(222, 53, 11);
}