@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700");
@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700");

.clf:after, .clf:before {
    content: '';
    display: table;
}

.clf:after {
    clear: both;
}

html {
    font-size: 14px;
}

body {
    overflow-x: hidden;
    font-family: 'Open Sans', sans-serif;
    background-color: #f1f1f1;
    color: #333;
}

a {
    cursor: pointer;
    color: #3498db;
    text-decoration: none;
    background-color: transparent;
}

a:hover, a:focus {
    text-decoration: none;
    color: #1d6fa5;
}

a.link {
    color: inherit;
}

a.link:hover, a.link:focus {
    text-decoration: none;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    font-weight: 400;
}

h6 {
    font-weight: 600;
}

h1 sup, .h1 sup {
    top: -10px;
}

h2 sup, .h2 sup {
    font-size: 20px;
    top: -7px;
}

h3 sup, .h3 sup {
    font-size: 16px;
    top: -5px;
}

small {
    font-size: 85%;
}

button:focus, select:focus, input:focus, a:focus {
    outline: none;
}

input, textarea, img, canvas {
    max-width: 100%;
}

button.reset {
    border: 0;
    background-color: inherit;
    padding: 0;
    outline: none;
}

strong {
    font-weight: 700;
}

b {
    font-weight: 700;
}

dt {
    font-weight: 700;
}

mark {
    padding: 1px 2px;
}

code {
    color: #cb4a4e;
    background-color: #f7f8f8;
}

pre {
    position: relative;
    border: none;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    margin-top: 10px;
    padding: 12px;
    background: #F0F0F0;
}

pre code {
    white-space: pre;
    display: block;
    overflow-x: auto;
}

.collapse.in {
    display: block;
}

.btn {
    -webkit-border-radius: 4px;
    border-radius: 4px;
    font-size: 14px;
    outline: 0 !important;
    -webkit-transition: all ease-in-out .15s;
    -o-transition: all ease-in-out .15s;
    transition: all ease-in-out .15s;
    position: relative;
}

.btn:active, .btn.active, .show>.btn.dropdown-toggle, .open>.btn.dropdown-toggle {
    -webkit-box-shadow: none;
    box-shadow: none;
}

.btn .active-visible {
    display: none;
}

.btn.active .active-visible {
    display: inline-block;
}

.btn.active .active-hidden {
    display: none;
}

.btn.btn-xs, .btn-group-xs .btn {
    padding: 2px 5px;
    font-size: 12px;
}

.btn-block {
    display: block;
    width: 100%;
}

.btn-fix {
    min-width: 120px;
    text-align: center;
}

.btn-flat {
    border: none;
}

.btn-transparent {
    border-color: transparent;
    background-color: transparent;
}

.btn-transparent:hover, .btn-transparent:focus {
    border-color: transparent;
    background-color: transparent;
}

.btn-default {
    color: #485b6f;
    background-color: #e3e6e7;
    border-color: #c1c4c4;
}

.btn-default:focus, .btn-default.focus, .btn-default:hover, .btn-default.active, .btn-default:active, .btn-default:disabled, .btn-default.disabled {
    color: #485b6f !important;
    background-color: #d5dadb !important;
    border-color: #b4b8b8 !important;
    -webkit-box-shadow: none;
    box-shadow: none;
    background-image: none;
}

.btn-primary {
    color: #fff;
    background-color: #3498db;
    border-color: #2c81ba;
}

.btn-primary:focus, .btn-primary.focus, .btn-primary:hover, .btn-primary.active, .btn-primary:active, .btn-primary:disabled, .btn-primary.disabled {
    color: #fff !important;
    background-color: #258cd1 !important;
    border-color: #2773a5 !important;
    -webkit-box-shadow: none;
    box-shadow: none;
    background-image: none;
}

.btn-success {
    color: #fff;
    background-color: #2ecc71;
    border-color: #27ad60;
}

.btn-success:focus, .btn-success.focus, .btn-success:hover, .btn-success.active, .btn-success:active, .btn-success:disabled, .btn-success.disabled {
    color: #fff !important;
    background-color: #29b765 !important;
    border-color: #229854 !important;
    -webkit-box-shadow: none;
    box-shadow: none;
    background-image: none;
}

.btn-info {
    color: #fff;
    background-color: #23B7E5;
    border-color: #1e9cc3;
}

.btn-info:focus, .btn-info.focus, .btn-info:hover, .btn-info.active, .btn-info:active, .btn-info:disabled, .btn-info.disabled {
    color: #fff !important;
    background-color: #19a9d5 !important;
    border-color: #1b8aad !important;
    -webkit-box-shadow: none;
    box-shadow: none;
    background-image: none;
}

.btn-warning {
    color: #fff;
    background-color: #F39C12;
    border-color: #cf850f;
}

.btn-warning:focus, .btn-warning.focus, .btn-warning:hover, .btn-warning.active, .btn-warning:active, .btn-warning:disabled, .btn-warning.disabled {
    color: #fff !important;
    background-color: #e08e0b !important;
    border-color: #b7760d !important;
    -webkit-box-shadow: none;
    box-shadow: none;
    background-image: none;
}

.btn-danger {
    color: #fff;
    background-color: #e74c3c;
    border-color: #c44133;
}

.btn-danger:focus, .btn-danger.focus, .btn-danger:hover, .btn-danger.active, .btn-danger:active, .btn-danger:disabled, .btn-danger.disabled {
    color: #fff !important;
    background-color: #e43725 !important;
    border-color: #b03a2e !important;
    -webkit-box-shadow: none;
    box-shadow: none;
    background-image: none;
}

.btn-outline-default {
    color: #485b6f;
    background-color: #fff;
    border-color: #ccc;
}

.btn-outline-default:focus, .btn-outline-default.focus, .btn-outline-default:hover, .btn-outline-default.active, .btn-outline-default:active, .btn-outline-default:disabled, .btn-outline-default.disabled {
    color: #485b6f !important;
    background-color: #f2f2f2 !important;
    border-color: #bfbfbf !important;
    -webkit-box-shadow: none;
    box-shadow: none;
    background-image: none;
}

.btn-outline-primary {
    color: #3498db;
    background-image: none;
    background-color: transparent;
    border-color: #3498db;
}

.btn-outline-primary:focus, .btn-outline-primary.focus, .btn-outline-primary:hover, .btn-outline-primary.active, .btn-outline-primary:active {
    color: #fff !important;
    background-color: #3498db !important;
    border-color: #3498db !important;
    -webkit-box-shadow: none;
    box-shadow: none;
    background-image: none;
}

.btn-outline-info {
    color: #23B7E5;
    background-image: none;
    background-color: transparent;
    border-color: #23B7E5;
}

.btn-outline-info:focus, .btn-outline-info.focus, .btn-outline-info:hover, .btn-outline-info.active, .btn-outline-info:active {
    color: #fff !important;
    background-color: #23B7E5 !important;
    border-color: #23B7E5 !important;
    -webkit-box-shadow: none;
    box-shadow: none;
    background-image: none;
}

.btn-outline-success {
    color: #2ecc71;
    background-image: none;
    background-color: transparent;
    border-color: #2ecc71;
}

.btn-outline-success:focus, .btn-outline-success.focus, .btn-outline-success:hover, .btn-outline-success.active, .btn-outline-success:active {
    color: #fff !important;
    background-color: #2ecc71 !important;
    border-color: #2ecc71 !important;
    -webkit-box-shadow: none;
    box-shadow: none;
    background-image: none;
}

.btn-outline-warning {
    color: #F39C12;
    background-image: none;
    background-color: transparent;
    border-color: #F39C12;
}

.btn-outline-warning:focus, .btn-outline-warning.focus, .btn-outline-warning:hover, .btn-outline-warning.active, .btn-outline-warning:active {
    color: #fff !important;
    background-color: #F39C12 !important;
    border-color: #F39C12 !important;
    -webkit-box-shadow: none;
    box-shadow: none;
    background-image: none;
}

.btn-outline-danger {
    color: #e74c3c;
    background-image: none;
    background-color: transparent;
    border-color: #e74c3c;
}

.btn-outline-danger:focus, .btn-outline-danger.focus, .btn-outline-danger:hover, .btn-outline-danger.active, .btn-outline-danger:active {
    color: #fff !important;
    background-color: #e74c3c !important;
    border-color: #e74c3c !important;
    -webkit-box-shadow: none;
    box-shadow: none;
    background-image: none;
}

.btn-rounded {
    -webkit-border-radius: 50px !important;
    border-radius: 50px !important;
}

.btn-group.btn-rounded>.btn:first-child {
    -webkit-border-radius: 50px 0 0 50px !important;
    border-radius: 50px 0 0 50px !important;
}

.btn-group.btn-rounded>.btn:last-child {
    -webkit-border-radius: 0 50px 50px 0 !important;
    border-radius: 0 50px 50px 0 !important;
}

.btn-group-vertical.btn-rounded>.btn:first-child {
    -webkit-border-radius: 50px 50px 0 0 !important;
    border-radius: 50px 50px 0 0 !important;
}

.btn-group-vertical.btn-rounded>.btn:last-child {
    -webkit-border-radius: 0 0 50px 50px !important;
    border-radius: 0 0 50px 50px !important;
}

.btn-circle {
    height: 36px;
    width: 36px;
    padding-left: 0 !important;
    padding-right: 0 !important;
    -webkit-border-radius: 50px;
    border-radius: 50px;
    text-align: center;
}

.btn-circle.btn-lg {
    height: 46px;
    width: 46px;
}

.btn-circle.btn-sm {
    height: 30px;
    width: 30px;
}

.btn-circle.btn-xs {
    height: 22px;
    width: 22px;
}

.caret {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 4px dashed;
    border-top: 4px solid\9;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
}

btn .caret {
    margin-left: 0;
}

.btn-social {
    position: relative;
    padding-left: 44px;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
}

.btn-social> :first-child {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 32px;
    line-height: 34px;
    font-size: 1.6em;
    text-align: center;
    border-right: 1px solid rgba(0, 0, 0, 0.2);
}

.btn-social.btn-lg {
    padding-left: 61px;
}

.btn-social.btn-lg> :first-child {
    line-height: 45px;
    width: 45px;
    font-size: 1.8em;
}

.btn-social.btn-sm {
    padding-left: 38px;
}

.btn-social.btn-sm> :first-child {
    line-height: 28px;
    width: 28px;
    font-size: 1.4em;
}

.btn-social.btn-xs {
    padding-left: 30px;
}

.btn-social.btn-xs> :first-child {
    line-height: 20px;
    width: 20px;
    font-size: 1.2em;
}

.btn-social-icon {
    position: relative;
    padding-left: 44px;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    height: 34px;
    width: 34px;
    padding: 0;
}

.btn-social-icon> :first-child {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 32px;
    line-height: 34px;
    font-size: 1.6em;
    text-align: center;
    border-right: 1px solid rgba(0, 0, 0, 0.2);
}

.btn-social-icon.btn-lg {
    padding-left: 61px;
}

.btn-social-icon.btn-lg> :first-child {
    line-height: 45px;
    width: 45px;
    font-size: 1.8em;
}

.btn-social-icon.btn-sm {
    padding-left: 38px;
}

.btn-social-icon.btn-sm> :first-child {
    line-height: 28px;
    width: 28px;
    font-size: 1.4em;
}

.btn-social-icon.btn-xs {
    padding-left: 30px;
}

.btn-social-icon.btn-xs> :first-child {
    line-height: 20px;
    width: 20px;
    font-size: 1.2em;
}

.btn-social-icon> :first-child {
    border: none;
    text-align: center;
    width: 100% !important;
}

.btn-social-icon.btn-lg {
    height: 45px;
    width: 45px;
    padding-left: 0;
    padding-right: 0;
}

.btn-social-icon.btn-sm {
    height: 30px;
    width: 30px;
    padding-left: 0;
    padding-right: 0;
}

.btn-social-icon.btn-xs {
    height: 22px;
    width: 22px;
    padding-left: 0;
    padding-right: 0;
}

.btn-adn {
    color: #fff;
    background-color: #d87a68;
    border-color: rgba(0, 0, 0, 0.2);
}

.btn-adn:focus, .btn-adn.focus {
    color: #fff;
    background-color: #ce563f;
    border-color: rgba(0, 0, 0, 0.2);
}

.btn-adn:hover {
    color: #fff;
    background-color: #ce563f;
    border-color: rgba(0, 0, 0, 0.2);
}

.btn-adn:active, .btn-adn.active, .open>.dropdown-toggle.btn-adn {
    color: #fff;
    background-color: #ce563f;
    border-color: rgba(0, 0, 0, 0.2);
}

.btn-adn:active:hover, .btn-adn.active:hover, .open>.dropdown-toggle.btn-adn:hover, .btn-adn:active:focus, .btn-adn.active:focus, .open>.dropdown-toggle.btn-adn:focus, .btn-adn:active.focus, .btn-adn.active.focus, .open>.dropdown-toggle.btn-adn.focus {
    color: #fff;
    background-color: #b94630;
    border-color: rgba(0, 0, 0, 0.2);
}

.btn-adn:active, .btn-adn.active, .open>.dropdown-toggle.btn-adn {
    background-image: none;
}

.btn-adn.disabled:hover, .btn-adn[disabled]:hover, fieldset[disabled] .btn-adn:hover, .btn-adn.disabled:focus, .btn-adn[disabled]:focus, fieldset[disabled] .btn-adn:focus, .btn-adn.disabled.focus, .btn-adn[disabled].focus, fieldset[disabled] .btn-adn.focus {
    background-color: #d87a68;
    border-color: rgba(0, 0, 0, 0.2);
}

.btn-adn .badge {
    color: #d87a68;
    background-color: #fff;
}

.btn-bitbucket {
    color: #fff;
    background-color: #205081;
    border-color: rgba(0, 0, 0, 0.2);
}

.btn-bitbucket:focus, .btn-bitbucket.focus {
    color: #fff;
    background-color: #163758;
    border-color: rgba(0, 0, 0, 0.2);
}

.btn-bitbucket:hover {
    color: #fff;
    background-color: #163758;
    border-color: rgba(0, 0, 0, 0.2);
}

.btn-bitbucket:active, .btn-bitbucket.active, .open>.dropdown-toggle.btn-bitbucket {
    color: #fff;
    background-color: #163758;
    border-color: rgba(0, 0, 0, 0.2);
}

.btn-bitbucket:active:hover, .btn-bitbucket.active:hover, .open>.dropdown-toggle.btn-bitbucket:hover, .btn-bitbucket:active:focus, .btn-bitbucket.active:focus, .open>.dropdown-toggle.btn-bitbucket:focus, .btn-bitbucket:active.focus, .btn-bitbucket.active.focus, .open>.dropdown-toggle.btn-bitbucket.focus {
    color: #fff;
    background-color: #0f253c;
    border-color: rgba(0, 0, 0, 0.2);
}

.btn-bitbucket:active, .btn-bitbucket.active, .open>.dropdown-toggle.btn-bitbucket {
    background-image: none;
}

.btn-bitbucket.disabled:hover, .btn-bitbucket[disabled]:hover, fieldset[disabled] .btn-bitbucket:hover, .btn-bitbucket.disabled:focus, .btn-bitbucket[disabled]:focus, fieldset[disabled] .btn-bitbucket:focus, .btn-bitbucket.disabled.focus, .btn-bitbucket[disabled].focus, fieldset[disabled] .btn-bitbucket.focus {
    background-color: #205081;
    border-color: rgba(0, 0, 0, 0.2);
}

.btn-bitbucket .badge {
    color: #205081;
    background-color: #fff;
}

.btn-dropbox {
    color: #fff;
    background-color: #1087dd;
    border-color: rgba(0, 0, 0, 0.2);
}

.btn-dropbox:focus, .btn-dropbox.focus {
    color: #fff;
    background-color: #0d6aad;
    border-color: rgba(0, 0, 0, 0.2);
}

.btn-dropbox:hover {
    color: #fff;
    background-color: #0d6aad;
    border-color: rgba(0, 0, 0, 0.2);
}

.btn-dropbox:active, .btn-dropbox.active, .open>.dropdown-toggle.btn-dropbox {
    color: #fff;
    background-color: #0d6aad;
    border-color: rgba(0, 0, 0, 0.2);
}

.btn-dropbox:active:hover, .btn-dropbox.active:hover, .open>.dropdown-toggle.btn-dropbox:hover, .btn-dropbox:active:focus, .btn-dropbox.active:focus, .open>.dropdown-toggle.btn-dropbox:focus, .btn-dropbox:active.focus, .btn-dropbox.active.focus, .open>.dropdown-toggle.btn-dropbox.focus {
    color: #fff;
    background-color: #0a568c;
    border-color: rgba(0, 0, 0, 0.2);
}

.btn-dropbox:active, .btn-dropbox.active, .open>.dropdown-toggle.btn-dropbox {
    background-image: none;
}

.btn-dropbox.disabled:hover, .btn-dropbox[disabled]:hover, fieldset[disabled] .btn-dropbox:hover, .btn-dropbox.disabled:focus, .btn-dropbox[disabled]:focus, fieldset[disabled] .btn-dropbox:focus, .btn-dropbox.disabled.focus, .btn-dropbox[disabled].focus, fieldset[disabled] .btn-dropbox.focus {
    background-color: #1087dd;
    border-color: rgba(0, 0, 0, 0.2);
}

.btn-dropbox .badge {
    color: #1087dd;
    background-color: #fff;
}

.btn-facebook {
    color: #fff;
    background-color: #3b5998;
    border-color: rgba(0, 0, 0, 0.2);
}

.btn-facebook:focus, .btn-facebook.focus {
    color: #fff;
    background-color: #2d4373;
    border-color: rgba(0, 0, 0, 0.2);
}

.btn-facebook:hover {
    color: #fff;
    background-color: #2d4373;
    border-color: rgba(0, 0, 0, 0.2);
}

.btn-facebook:active, .btn-facebook.active, .open>.dropdown-toggle.btn-facebook {
    color: #fff;
    background-color: #2d4373;
    border-color: rgba(0, 0, 0, 0.2);
}

.btn-facebook:active:hover, .btn-facebook.active:hover, .open>.dropdown-toggle.btn-facebook:hover, .btn-facebook:active:focus, .btn-facebook.active:focus, .open>.dropdown-toggle.btn-facebook:focus, .btn-facebook:active.focus, .btn-facebook.active.focus, .open>.dropdown-toggle.btn-facebook.focus {
    color: #fff;
    background-color: #23345a;
    border-color: rgba(0, 0, 0, 0.2);
}

.btn-facebook:active, .btn-facebook.active, .open>.dropdown-toggle.btn-facebook {
    background-image: none;
}

.btn-facebook.disabled:hover, .btn-facebook[disabled]:hover, fieldset[disabled] .btn-facebook:hover, .btn-facebook.disabled:focus, .btn-facebook[disabled]:focus, fieldset[disabled] .btn-facebook:focus, .btn-facebook.disabled.focus, .btn-facebook[disabled].focus, fieldset[disabled] .btn-facebook.focus {
    background-color: #3b5998;
    border-color: rgba(0, 0, 0, 0.2);
}

.btn-facebook .badge {
    color: #3b5998;
    background-color: #fff;
}

.btn-flickr {
    color: #fff;
    background-color: #ff0084;
    border-color: rgba(0, 0, 0, 0.2);
}

.btn-flickr:focus, .btn-flickr.focus {
    color: #fff;
    background-color: #cc006a;
    border-color: rgba(0, 0, 0, 0.2);
}

.btn-flickr:hover {
    color: #fff;
    background-color: #cc006a;
    border-color: rgba(0, 0, 0, 0.2);
}

.btn-flickr:active, .btn-flickr.active, .open>.dropdown-toggle.btn-flickr {
    color: #fff;
    background-color: #cc006a;
    border-color: rgba(0, 0, 0, 0.2);
}

.btn-flickr:active:hover, .btn-flickr.active:hover, .open>.dropdown-toggle.btn-flickr:hover, .btn-flickr:active:focus, .btn-flickr.active:focus, .open>.dropdown-toggle.btn-flickr:focus, .btn-flickr:active.focus, .btn-flickr.active.focus, .open>.dropdown-toggle.btn-flickr.focus {
    color: #fff;
    background-color: #a80057;
    border-color: rgba(0, 0, 0, 0.2);
}

.btn-flickr:active, .btn-flickr.active, .open>.dropdown-toggle.btn-flickr {
    background-image: none;
}

.btn-flickr.disabled:hover, .btn-flickr[disabled]:hover, fieldset[disabled] .btn-flickr:hover, .btn-flickr.disabled:focus, .btn-flickr[disabled]:focus, fieldset[disabled] .btn-flickr:focus, .btn-flickr.disabled.focus, .btn-flickr[disabled].focus, fieldset[disabled] .btn-flickr.focus {
    background-color: #ff0084;
    border-color: rgba(0, 0, 0, 0.2);
}

.btn-flickr .badge {
    color: #ff0084;
    background-color: #fff;
}

.btn-foursquare {
    color: #fff;
    background-color: #f94877;
    border-color: rgba(0, 0, 0, 0.2);
}

.btn-foursquare:focus, .btn-foursquare.focus {
    color: #fff;
    background-color: #f71752;
    border-color: rgba(0, 0, 0, 0.2);
}

.btn-foursquare:hover {
    color: #fff;
    background-color: #f71752;
    border-color: rgba(0, 0, 0, 0.2);
}

.btn-foursquare:active, .btn-foursquare.active, .open>.dropdown-toggle.btn-foursquare {
    color: #fff;
    background-color: #f71752;
    border-color: rgba(0, 0, 0, 0.2);
}

.btn-foursquare:active:hover, .btn-foursquare.active:hover, .open>.dropdown-toggle.btn-foursquare:hover, .btn-foursquare:active:focus, .btn-foursquare.active:focus, .open>.dropdown-toggle.btn-foursquare:focus, .btn-foursquare:active.focus, .btn-foursquare.active.focus, .open>.dropdown-toggle.btn-foursquare.focus {
    color: #fff;
    background-color: #e30742;
    border-color: rgba(0, 0, 0, 0.2);
}

.btn-foursquare:active, .btn-foursquare.active, .open>.dropdown-toggle.btn-foursquare {
    background-image: none;
}

.btn-foursquare.disabled:hover, .btn-foursquare[disabled]:hover, fieldset[disabled] .btn-foursquare:hover, .btn-foursquare.disabled:focus, .btn-foursquare[disabled]:focus, fieldset[disabled] .btn-foursquare:focus, .btn-foursquare.disabled.focus, .btn-foursquare[disabled].focus, fieldset[disabled] .btn-foursquare.focus {
    background-color: #f94877;
    border-color: rgba(0, 0, 0, 0.2);
}

.btn-foursquare .badge {
    color: #f94877;
    background-color: #fff;
}

.btn-github {
    color: #fff;
    background-color: #444;
    border-color: rgba(0, 0, 0, 0.2);
}

.btn-github:focus, .btn-github.focus {
    color: #fff;
    background-color: #2b2b2b;
    border-color: rgba(0, 0, 0, 0.2);
}

.btn-github:hover {
    color: #fff;
    background-color: #2b2b2b;
    border-color: rgba(0, 0, 0, 0.2);
}

.btn-github:active, .btn-github.active, .open>.dropdown-toggle.btn-github {
    color: #fff;
    background-color: #2b2b2b;
    border-color: rgba(0, 0, 0, 0.2);
}

.btn-github:active:hover, .btn-github.active:hover, .open>.dropdown-toggle.btn-github:hover, .btn-github:active:focus, .btn-github.active:focus, .open>.dropdown-toggle.btn-github:focus, .btn-github:active.focus, .btn-github.active.focus, .open>.dropdown-toggle.btn-github.focus {
    color: #fff;
    background-color: #191919;
    border-color: rgba(0, 0, 0, 0.2);
}

.btn-github:active, .btn-github.active, .open>.dropdown-toggle.btn-github {
    background-image: none;
}

.btn-github.disabled:hover, .btn-github[disabled]:hover, fieldset[disabled] .btn-github:hover, .btn-github.disabled:focus, .btn-github[disabled]:focus, fieldset[disabled] .btn-github:focus, .btn-github.disabled.focus, .btn-github[disabled].focus, fieldset[disabled] .btn-github.focus {
    background-color: #444;
    border-color: rgba(0, 0, 0, 0.2);
}

.btn-github .badge {
    color: #444;
    background-color: #fff;
}

.btn-google {
    color: #fff;
    background-color: #dd4b39;
    border-color: rgba(0, 0, 0, 0.2);
}

.btn-google:focus, .btn-google.focus {
    color: #fff;
    background-color: #c23321;
    border-color: rgba(0, 0, 0, 0.2);
}

.btn-google:hover {
    color: #fff;
    background-color: #c23321;
    border-color: rgba(0, 0, 0, 0.2);
}

.btn-google:active, .btn-google.active, .open>.dropdown-toggle.btn-google {
    color: #fff;
    background-color: #c23321;
    border-color: rgba(0, 0, 0, 0.2);
}

.btn-google:active:hover, .btn-google.active:hover, .open>.dropdown-toggle.btn-google:hover, .btn-google:active:focus, .btn-google.active:focus, .open>.dropdown-toggle.btn-google:focus, .btn-google:active.focus, .btn-google.active.focus, .open>.dropdown-toggle.btn-google.focus {
    color: #fff;
    background-color: #a32b1c;
    border-color: rgba(0, 0, 0, 0.2);
}

.btn-google:active, .btn-google.active, .open>.dropdown-toggle.btn-google {
    background-image: none;
}

.btn-google.disabled:hover, .btn-google[disabled]:hover, fieldset[disabled] .btn-google:hover, .btn-google.disabled:focus, .btn-google[disabled]:focus, fieldset[disabled] .btn-google:focus, .btn-google.disabled.focus, .btn-google[disabled].focus, fieldset[disabled] .btn-google.focus {
    background-color: #dd4b39;
    border-color: rgba(0, 0, 0, 0.2);
}

.btn-google .badge {
    color: #dd4b39;
    background-color: #fff;
}

.btn-instagram {
    color: #fff;
    background-color: #3f729b;
    border-color: rgba(0, 0, 0, 0.2);
}

.btn-instagram:focus, .btn-instagram.focus {
    color: #fff;
    background-color: #305777;
    border-color: rgba(0, 0, 0, 0.2);
}

.btn-instagram:hover {
    color: #fff;
    background-color: #305777;
    border-color: rgba(0, 0, 0, 0.2);
}

.btn-instagram:active, .btn-instagram.active, .open>.dropdown-toggle.btn-instagram {
    color: #fff;
    background-color: #305777;
    border-color: rgba(0, 0, 0, 0.2);
}

.btn-instagram:active:hover, .btn-instagram.active:hover, .open>.dropdown-toggle.btn-instagram:hover, .btn-instagram:active:focus, .btn-instagram.active:focus, .open>.dropdown-toggle.btn-instagram:focus, .btn-instagram:active.focus, .btn-instagram.active.focus, .open>.dropdown-toggle.btn-instagram.focus {
    color: #fff;
    background-color: #26455d;
    border-color: rgba(0, 0, 0, 0.2);
}

.btn-instagram:active, .btn-instagram.active, .open>.dropdown-toggle.btn-instagram {
    background-image: none;
}

.btn-instagram.disabled:hover, .btn-instagram[disabled]:hover, fieldset[disabled] .btn-instagram:hover, .btn-instagram.disabled:focus, .btn-instagram[disabled]:focus, fieldset[disabled] .btn-instagram:focus, .btn-instagram.disabled.focus, .btn-instagram[disabled].focus, fieldset[disabled] .btn-instagram.focus {
    background-color: #3f729b;
    border-color: rgba(0, 0, 0, 0.2);
}

.btn-instagram .badge {
    color: #3f729b;
    background-color: #fff;
}

.btn-linkedin {
    color: #fff;
    background-color: #007bb6;
    border-color: rgba(0, 0, 0, 0.2);
}

.btn-linkedin:focus, .btn-linkedin.focus {
    color: #fff;
    background-color: #005983;
    border-color: rgba(0, 0, 0, 0.2);
}

.btn-linkedin:hover {
    color: #fff;
    background-color: #005983;
    border-color: rgba(0, 0, 0, 0.2);
}

.btn-linkedin:active, .btn-linkedin.active, .open>.dropdown-toggle.btn-linkedin {
    color: #fff;
    background-color: #005983;
    border-color: rgba(0, 0, 0, 0.2);
}

.btn-linkedin:active:hover, .btn-linkedin.active:hover, .open>.dropdown-toggle.btn-linkedin:hover, .btn-linkedin:active:focus, .btn-linkedin.active:focus, .open>.dropdown-toggle.btn-linkedin:focus, .btn-linkedin:active.focus, .btn-linkedin.active.focus, .open>.dropdown-toggle.btn-linkedin.focus {
    color: #fff;
    background-color: #00405f;
    border-color: rgba(0, 0, 0, 0.2);
}

.btn-linkedin:active, .btn-linkedin.active, .open>.dropdown-toggle.btn-linkedin {
    background-image: none;
}

.btn-linkedin.disabled:hover, .btn-linkedin[disabled]:hover, fieldset[disabled] .btn-linkedin:hover, .btn-linkedin.disabled:focus, .btn-linkedin[disabled]:focus, fieldset[disabled] .btn-linkedin:focus, .btn-linkedin.disabled.focus, .btn-linkedin[disabled].focus, fieldset[disabled] .btn-linkedin.focus {
    background-color: #007bb6;
    border-color: rgba(0, 0, 0, 0.2);
}

.btn-linkedin .badge {
    color: #007bb6;
    background-color: #fff;
}

.btn-microsoft {
    color: #fff;
    background-color: #2672ec;
    border-color: rgba(0, 0, 0, 0.2);
}

.btn-microsoft:focus, .btn-microsoft.focus {
    color: #fff;
    background-color: #125acd;
    border-color: rgba(0, 0, 0, 0.2);
}

.btn-microsoft:hover {
    color: #fff;
    background-color: #125acd;
    border-color: rgba(0, 0, 0, 0.2);
}

.btn-microsoft:active, .btn-microsoft.active, .open>.dropdown-toggle.btn-microsoft {
    color: #fff;
    background-color: #125acd;
    border-color: rgba(0, 0, 0, 0.2);
}

.btn-microsoft:active:hover, .btn-microsoft.active:hover, .open>.dropdown-toggle.btn-microsoft:hover, .btn-microsoft:active:focus, .btn-microsoft.active:focus, .open>.dropdown-toggle.btn-microsoft:focus, .btn-microsoft:active.focus, .btn-microsoft.active.focus, .open>.dropdown-toggle.btn-microsoft.focus {
    color: #fff;
    background-color: #0f4bac;
    border-color: rgba(0, 0, 0, 0.2);
}

.btn-microsoft:active, .btn-microsoft.active, .open>.dropdown-toggle.btn-microsoft {
    background-image: none;
}

.btn-microsoft.disabled:hover, .btn-microsoft[disabled]:hover, fieldset[disabled] .btn-microsoft:hover, .btn-microsoft.disabled:focus, .btn-microsoft[disabled]:focus, fieldset[disabled] .btn-microsoft:focus, .btn-microsoft.disabled.focus, .btn-microsoft[disabled].focus, fieldset[disabled] .btn-microsoft.focus {
    background-color: #2672ec;
    border-color: rgba(0, 0, 0, 0.2);
}

.btn-microsoft .badge {
    color: #2672ec;
    background-color: #fff;
}

.btn-odnoklassniki {
    color: #fff;
    background-color: #f4731c;
    border-color: rgba(0, 0, 0, 0.2);
}

.btn-odnoklassniki:focus, .btn-odnoklassniki.focus {
    color: #fff;
    background-color: #d35b0a;
    border-color: rgba(0, 0, 0, 0.2);
}

.btn-odnoklassniki:hover {
    color: #fff;
    background-color: #d35b0a;
    border-color: rgba(0, 0, 0, 0.2);
}

.btn-odnoklassniki:active, .btn-odnoklassniki.active, .open>.dropdown-toggle.btn-odnoklassniki {
    color: #fff;
    background-color: #d35b0a;
    border-color: rgba(0, 0, 0, 0.2);
}

.btn-odnoklassniki:active:hover, .btn-odnoklassniki.active:hover, .open>.dropdown-toggle.btn-odnoklassniki:hover, .btn-odnoklassniki:active:focus, .btn-odnoklassniki.active:focus, .open>.dropdown-toggle.btn-odnoklassniki:focus, .btn-odnoklassniki:active.focus, .btn-odnoklassniki.active.focus, .open>.dropdown-toggle.btn-odnoklassniki.focus {
    color: #fff;
    background-color: #b14c09;
    border-color: rgba(0, 0, 0, 0.2);
}

.btn-odnoklassniki:active, .btn-odnoklassniki.active, .open>.dropdown-toggle.btn-odnoklassniki {
    background-image: none;
}

.btn-odnoklassniki.disabled:hover, .btn-odnoklassniki[disabled]:hover, fieldset[disabled] .btn-odnoklassniki:hover, .btn-odnoklassniki.disabled:focus, .btn-odnoklassniki[disabled]:focus, fieldset[disabled] .btn-odnoklassniki:focus, .btn-odnoklassniki.disabled.focus, .btn-odnoklassniki[disabled].focus, fieldset[disabled] .btn-odnoklassniki.focus {
    background-color: #f4731c;
    border-color: rgba(0, 0, 0, 0.2);
}

.btn-odnoklassniki .badge {
    color: #f4731c;
    background-color: #fff;
}

.btn-openid {
    color: #fff;
    background-color: #f7931e;
    border-color: rgba(0, 0, 0, 0.2);
}

.btn-openid:focus, .btn-openid.focus {
    color: #fff;
    background-color: #da7908;
    border-color: rgba(0, 0, 0, 0.2);
}

.btn-openid:hover {
    color: #fff;
    background-color: #da7908;
    border-color: rgba(0, 0, 0, 0.2);
}

.btn-openid:active, .btn-openid.active, .open>.dropdown-toggle.btn-openid {
    color: #fff;
    background-color: #da7908;
    border-color: rgba(0, 0, 0, 0.2);
}

.btn-openid:active:hover, .btn-openid.active:hover, .open>.dropdown-toggle.btn-openid:hover, .btn-openid:active:focus, .btn-openid.active:focus, .open>.dropdown-toggle.btn-openid:focus, .btn-openid:active.focus, .btn-openid.active.focus, .open>.dropdown-toggle.btn-openid.focus {
    color: #fff;
    background-color: #b86607;
    border-color: rgba(0, 0, 0, 0.2);
}

.btn-openid:active, .btn-openid.active, .open>.dropdown-toggle.btn-openid {
    background-image: none;
}

.btn-openid.disabled:hover, .btn-openid[disabled]:hover, fieldset[disabled] .btn-openid:hover, .btn-openid.disabled:focus, .btn-openid[disabled]:focus, fieldset[disabled] .btn-openid:focus, .btn-openid.disabled.focus, .btn-openid[disabled].focus, fieldset[disabled] .btn-openid.focus {
    background-color: #f7931e;
    border-color: rgba(0, 0, 0, 0.2);
}

.btn-openid .badge {
    color: #f7931e;
    background-color: #fff;
}

.btn-pinterest {
    color: #fff;
    background-color: #cb2027;
    border-color: rgba(0, 0, 0, 0.2);
}

.btn-pinterest:focus, .btn-pinterest.focus {
    color: #fff;
    background-color: #9f191f;
    border-color: rgba(0, 0, 0, 0.2);
}

.btn-pinterest:hover {
    color: #fff;
    background-color: #9f191f;
    border-color: rgba(0, 0, 0, 0.2);
}

.btn-pinterest:active, .btn-pinterest.active, .open>.dropdown-toggle.btn-pinterest {
    color: #fff;
    background-color: #9f191f;
    border-color: rgba(0, 0, 0, 0.2);
}

.btn-pinterest:active:hover, .btn-pinterest.active:hover, .open>.dropdown-toggle.btn-pinterest:hover, .btn-pinterest:active:focus, .btn-pinterest.active:focus, .open>.dropdown-toggle.btn-pinterest:focus, .btn-pinterest:active.focus, .btn-pinterest.active.focus, .open>.dropdown-toggle.btn-pinterest.focus {
    color: #fff;
    background-color: #801419;
    border-color: rgba(0, 0, 0, 0.2);
}

.btn-pinterest:active, .btn-pinterest.active, .open>.dropdown-toggle.btn-pinterest {
    background-image: none;
}

.btn-pinterest.disabled:hover, .btn-pinterest[disabled]:hover, fieldset[disabled] .btn-pinterest:hover, .btn-pinterest.disabled:focus, .btn-pinterest[disabled]:focus, fieldset[disabled] .btn-pinterest:focus, .btn-pinterest.disabled.focus, .btn-pinterest[disabled].focus, fieldset[disabled] .btn-pinterest.focus {
    background-color: #cb2027;
    border-color: rgba(0, 0, 0, 0.2);
}

.btn-pinterest .badge {
    color: #cb2027;
    background-color: #fff;
}

.btn-reddit {
    color: #000;
    background-color: #eff7ff;
    border-color: rgba(0, 0, 0, 0.2);
}

.btn-reddit:focus, .btn-reddit.focus {
    color: #000;
    background-color: #bcddff;
    border-color: rgba(0, 0, 0, 0.2);
}

.btn-reddit:hover {
    color: #000;
    background-color: #bcddff;
    border-color: rgba(0, 0, 0, 0.2);
}

.btn-reddit:active, .btn-reddit.active, .open>.dropdown-toggle.btn-reddit {
    color: #000;
    background-color: #bcddff;
    border-color: rgba(0, 0, 0, 0.2);
}

.btn-reddit:active:hover, .btn-reddit.active:hover, .open>.dropdown-toggle.btn-reddit:hover, .btn-reddit:active:focus, .btn-reddit.active:focus, .open>.dropdown-toggle.btn-reddit:focus, .btn-reddit:active.focus, .btn-reddit.active.focus, .open>.dropdown-toggle.btn-reddit.focus {
    color: #000;
    background-color: #98ccff;
    border-color: rgba(0, 0, 0, 0.2);
}

.btn-reddit:active, .btn-reddit.active, .open>.dropdown-toggle.btn-reddit {
    background-image: none;
}

.btn-reddit.disabled:hover, .btn-reddit[disabled]:hover, fieldset[disabled] .btn-reddit:hover, .btn-reddit.disabled:focus, .btn-reddit[disabled]:focus, fieldset[disabled] .btn-reddit:focus, .btn-reddit.disabled.focus, .btn-reddit[disabled].focus, fieldset[disabled] .btn-reddit.focus {
    background-color: #eff7ff;
    border-color: rgba(0, 0, 0, 0.2);
}

.btn-reddit .badge {
    color: #eff7ff;
    background-color: #000;
}

.btn-soundcloud {
    color: #fff;
    background-color: #f50;
    border-color: rgba(0, 0, 0, 0.2);
}

.btn-soundcloud:focus, .btn-soundcloud.focus {
    color: #fff;
    background-color: #c40;
    border-color: rgba(0, 0, 0, 0.2);
}

.btn-soundcloud:hover {
    color: #fff;
    background-color: #c40;
    border-color: rgba(0, 0, 0, 0.2);
}

.btn-soundcloud:active, .btn-soundcloud.active, .open>.dropdown-toggle.btn-soundcloud {
    color: #fff;
    background-color: #c40;
    border-color: rgba(0, 0, 0, 0.2);
}

.btn-soundcloud:active:hover, .btn-soundcloud.active:hover, .open>.dropdown-toggle.btn-soundcloud:hover, .btn-soundcloud:active:focus, .btn-soundcloud.active:focus, .open>.dropdown-toggle.btn-soundcloud:focus, .btn-soundcloud:active.focus, .btn-soundcloud.active.focus, .open>.dropdown-toggle.btn-soundcloud.focus {
    color: #fff;
    background-color: #a83800;
    border-color: rgba(0, 0, 0, 0.2);
}

.btn-soundcloud:active, .btn-soundcloud.active, .open>.dropdown-toggle.btn-soundcloud {
    background-image: none;
}

.btn-soundcloud.disabled:hover, .btn-soundcloud[disabled]:hover, fieldset[disabled] .btn-soundcloud:hover, .btn-soundcloud.disabled:focus, .btn-soundcloud[disabled]:focus, fieldset[disabled] .btn-soundcloud:focus, .btn-soundcloud.disabled.focus, .btn-soundcloud[disabled].focus, fieldset[disabled] .btn-soundcloud.focus {
    background-color: #f50;
    border-color: rgba(0, 0, 0, 0.2);
}

.btn-soundcloud .badge {
    color: #f50;
    background-color: #fff;
}

.btn-tumblr {
    color: #fff;
    background-color: #2c4762;
    border-color: rgba(0, 0, 0, 0.2);
}

.btn-tumblr:focus, .btn-tumblr.focus {
    color: #fff;
    background-color: #1c2d3f;
    border-color: rgba(0, 0, 0, 0.2);
}

.btn-tumblr:hover {
    color: #fff;
    background-color: #1c2d3f;
    border-color: rgba(0, 0, 0, 0.2);
}

.btn-tumblr:active, .btn-tumblr.active, .open>.dropdown-toggle.btn-tumblr {
    color: #fff;
    background-color: #1c2d3f;
    border-color: rgba(0, 0, 0, 0.2);
}

.btn-tumblr:active:hover, .btn-tumblr.active:hover, .open>.dropdown-toggle.btn-tumblr:hover, .btn-tumblr:active:focus, .btn-tumblr.active:focus, .open>.dropdown-toggle.btn-tumblr:focus, .btn-tumblr:active.focus, .btn-tumblr.active.focus, .open>.dropdown-toggle.btn-tumblr.focus {
    color: #fff;
    background-color: #111c26;
    border-color: rgba(0, 0, 0, 0.2);
}

.btn-tumblr:active, .btn-tumblr.active, .open>.dropdown-toggle.btn-tumblr {
    background-image: none;
}

.btn-tumblr.disabled:hover, .btn-tumblr[disabled]:hover, fieldset[disabled] .btn-tumblr:hover, .btn-tumblr.disabled:focus, .btn-tumblr[disabled]:focus, fieldset[disabled] .btn-tumblr:focus, .btn-tumblr.disabled.focus, .btn-tumblr[disabled].focus, fieldset[disabled] .btn-tumblr.focus {
    background-color: #2c4762;
    border-color: rgba(0, 0, 0, 0.2);
}

.btn-tumblr .badge {
    color: #2c4762;
    background-color: #fff;
}

.btn-twitter {
    color: #fff;
    background-color: #55acee;
    border-color: rgba(0, 0, 0, 0.2);
}

.btn-twitter:focus, .btn-twitter.focus {
    color: #fff;
    background-color: #2795e9;
    border-color: rgba(0, 0, 0, 0.2);
}

.btn-twitter:hover {
    color: #fff;
    background-color: #2795e9;
    border-color: rgba(0, 0, 0, 0.2);
}

.btn-twitter:active, .btn-twitter.active, .open>.dropdown-toggle.btn-twitter {
    color: #fff;
    background-color: #2795e9;
    border-color: rgba(0, 0, 0, 0.2);
}

.btn-twitter:active:hover, .btn-twitter.active:hover, .open>.dropdown-toggle.btn-twitter:hover, .btn-twitter:active:focus, .btn-twitter.active:focus, .open>.dropdown-toggle.btn-twitter:focus, .btn-twitter:active.focus, .btn-twitter.active.focus, .open>.dropdown-toggle.btn-twitter.focus {
    color: #fff;
    background-color: #1583d7;
    border-color: rgba(0, 0, 0, 0.2);
}

.btn-twitter:active, .btn-twitter.active, .open>.dropdown-toggle.btn-twitter {
    background-image: none;
}

.btn-twitter.disabled:hover, .btn-twitter[disabled]:hover, fieldset[disabled] .btn-twitter:hover, .btn-twitter.disabled:focus, .btn-twitter[disabled]:focus, fieldset[disabled] .btn-twitter:focus, .btn-twitter.disabled.focus, .btn-twitter[disabled].focus, fieldset[disabled] .btn-twitter.focus {
    background-color: #55acee;
    border-color: rgba(0, 0, 0, 0.2);
}

.btn-twitter .badge {
    color: #55acee;
    background-color: #fff;
}

.btn-vimeo {
    color: #fff;
    background-color: #1ab7ea;
    border-color: rgba(0, 0, 0, 0.2);
}

.btn-vimeo:focus, .btn-vimeo.focus {
    color: #fff;
    background-color: #1295bf;
    border-color: rgba(0, 0, 0, 0.2);
}

.btn-vimeo:hover {
    color: #fff;
    background-color: #1295bf;
    border-color: rgba(0, 0, 0, 0.2);
}

.btn-vimeo:active, .btn-vimeo.active, .open>.dropdown-toggle.btn-vimeo {
    color: #fff;
    background-color: #1295bf;
    border-color: rgba(0, 0, 0, 0.2);
}

.btn-vimeo:active:hover, .btn-vimeo.active:hover, .open>.dropdown-toggle.btn-vimeo:hover, .btn-vimeo:active:focus, .btn-vimeo.active:focus, .open>.dropdown-toggle.btn-vimeo:focus, .btn-vimeo:active.focus, .btn-vimeo.active.focus, .open>.dropdown-toggle.btn-vimeo.focus {
    color: #fff;
    background-color: #0f7b9f;
    border-color: rgba(0, 0, 0, 0.2);
}

.btn-vimeo:active, .btn-vimeo.active, .open>.dropdown-toggle.btn-vimeo {
    background-image: none;
}

.btn-vimeo.disabled:hover, .btn-vimeo[disabled]:hover, fieldset[disabled] .btn-vimeo:hover, .btn-vimeo.disabled:focus, .btn-vimeo[disabled]:focus, fieldset[disabled] .btn-vimeo:focus, .btn-vimeo.disabled.focus, .btn-vimeo[disabled].focus, fieldset[disabled] .btn-vimeo.focus {
    background-color: #1ab7ea;
    border-color: rgba(0, 0, 0, 0.2);
}

.btn-vimeo .badge {
    color: #1ab7ea;
    background-color: #fff;
}

.btn-vk {
    color: #fff;
    background-color: #587ea3;
    border-color: rgba(0, 0, 0, 0.2);
}

.btn-vk:focus, .btn-vk.focus {
    color: #fff;
    background-color: #466482;
    border-color: rgba(0, 0, 0, 0.2);
}

.btn-vk:hover {
    color: #fff;
    background-color: #466482;
    border-color: rgba(0, 0, 0, 0.2);
}

.btn-vk:active, .btn-vk.active, .open>.dropdown-toggle.btn-vk {
    color: #fff;
    background-color: #466482;
    border-color: rgba(0, 0, 0, 0.2);
}

.btn-vk:active:hover, .btn-vk.active:hover, .open>.dropdown-toggle.btn-vk:hover, .btn-vk:active:focus, .btn-vk.active:focus, .open>.dropdown-toggle.btn-vk:focus, .btn-vk:active.focus, .btn-vk.active.focus, .open>.dropdown-toggle.btn-vk.focus {
    color: #fff;
    background-color: #3a526b;
    border-color: rgba(0, 0, 0, 0.2);
}

.btn-vk:active, .btn-vk.active, .open>.dropdown-toggle.btn-vk {
    background-image: none;
}

.btn-vk.disabled:hover, .btn-vk[disabled]:hover, fieldset[disabled] .btn-vk:hover, .btn-vk.disabled:focus, .btn-vk[disabled]:focus, fieldset[disabled] .btn-vk:focus, .btn-vk.disabled.focus, .btn-vk[disabled].focus, fieldset[disabled] .btn-vk.focus {
    background-color: #587ea3;
    border-color: rgba(0, 0, 0, 0.2);
}

.btn-vk .badge {
    color: #587ea3;
    background-color: #fff;
}

.btn-yahoo {
    color: #fff;
    background-color: #720e9e;
    border-color: rgba(0, 0, 0, 0.2);
}

.btn-yahoo:focus, .btn-yahoo.focus {
    color: #fff;
    background-color: #500a6f;
    border-color: rgba(0, 0, 0, 0.2);
}

.btn-yahoo:hover {
    color: #fff;
    background-color: #500a6f;
    border-color: rgba(0, 0, 0, 0.2);
}

.btn-yahoo:active, .btn-yahoo.active, .open>.dropdown-toggle.btn-yahoo {
    color: #fff;
    background-color: #500a6f;
    border-color: rgba(0, 0, 0, 0.2);
}

.btn-yahoo:active:hover, .btn-yahoo.active:hover, .open>.dropdown-toggle.btn-yahoo:hover, .btn-yahoo:active:focus, .btn-yahoo.active:focus, .open>.dropdown-toggle.btn-yahoo:focus, .btn-yahoo:active.focus, .btn-yahoo.active.focus, .open>.dropdown-toggle.btn-yahoo.focus {
    color: #fff;
    background-color: #39074e;
    border-color: rgba(0, 0, 0, 0.2);
}

.btn-yahoo:active, .btn-yahoo.active, .open>.dropdown-toggle.btn-yahoo {
    background-image: none;
}

.btn-yahoo.disabled:hover, .btn-yahoo[disabled]:hover, fieldset[disabled] .btn-yahoo:hover, .btn-yahoo.disabled:focus, .btn-yahoo[disabled]:focus, fieldset[disabled] .btn-yahoo:focus, .btn-yahoo.disabled.focus, .btn-yahoo[disabled].focus, fieldset[disabled] .btn-yahoo.focus {
    background-color: #720e9e;
    border-color: rgba(0, 0, 0, 0.2);
}

.btn-yahoo .badge {
    color: #720e9e;
    background-color: #fff;
}

.alert-dismissible .btn-close {
    background: none;
}

.alert-dismissible .btn-close:focus {
    box-shadow: none;
}

.alert-dismissible .btn-close i {
    font-size: 20px;
}

.ibox {
    position: relative;
    margin-bottom: 25px;
    background-color: #fff;
    -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
}

.ibox .ibox-head {
    padding: 0 20px;
    border-bottom: 1px solid #eee;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    height: 50px;
}

.ibox .ibox-head .ibox-title {
    font-size: 16px;
    font-weight: 600;
}

.ibox .ibox-head .ibox-title small {
    font-weight: 400;
    font-size: 13px;
    color: #777;
}

.ibox .ibox-head .ibox-title small a {
    color: inherit;
}

.ibox .ibox-head .ibox-links a, .ibox .ibox-head .ibox-links button {
    margin-right: 5px;
}

.ibox .ibox-head .ibox-links a:last-child, .ibox .ibox-head .ibox-links button:last-child {
    margin-right: 0;
}

.ibox .ibox-head .ibox-tools {
    position: relative;
}

.ibox .ibox-head .ibox-tools>a {
    color: #aaa;
    font-size: 14px;
    padding: 0 6px;
}

.ibox .ibox-head .ibox-tools>a:hover {
    color: #6d7c85;
}

.ibox .ibox-head .nav-tabs {
    margin: 0;
    border: 0;
    height: 100%;
}

.ibox .ibox-head .nav-tabs .nav-link {
    height: 100%;
    padding: .9rem 1rem;
}

.ibox .ibox-body {
    position: relative;
    padding: 15px 20px 20px 20px;
}

.ibox .ibox-footer {
    padding: 10px 0;
    border-top: 1px solid #eee;
}

.ibox.ibox-fullscreen {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0;
    z-index: 1005;
}

.ibox.ibox-fullscreen .fullscreen-link>i::before {
    content: "\f066";
}

.ibox.collapsed-mode .ibox-collapse>i::before {
    content: "\f067";
}

.ibox-primary .ibox-head {
    color: #fff;
    background-color: #3498db;
}

.ibox-primary .ibox-head .ibox-tools>a, .ibox-primary .ibox-head .ibox-tools>a:hover {
    color: #fff;
}

.ibox-success .ibox-head {
    color: #fff;
    background-color: #2ecc71;
}

.ibox-success .ibox-head .ibox-tools>a, .ibox-success .ibox-head .ibox-tools>a:hover {
    color: #fff;
}

.ibox-info .ibox-head {
    color: #fff;
    background-color: #23B7E5;
}

.ibox-info .ibox-head .ibox-tools>a, .ibox-info .ibox-head .ibox-tools>a:hover {
    color: #fff;
}

.ibox-warning .ibox-head {
    color: #fff;
    background-color: #F39C12;
}

.ibox-warning .ibox-head .ibox-tools>a, .ibox-warning .ibox-head .ibox-tools>a:hover {
    color: #fff;
}

.ibox-danger .ibox-head {
    color: #fff;
    background-color: #e74c3c;
}

.ibox-danger .ibox-head .ibox-tools>a, .ibox-danger .ibox-head .ibox-tools>a:hover {
    color: #fff;
}

.ibox-grey .ibox-head {
    color: #fff;
    background-color: #bdc3c7;
}

.ibox-grey .ibox-head .ibox-tools>a, .ibox-grey .ibox-head .ibox-tools>a:hover {
    color: #fff;
}

.ibox-child {
    border: 1px solid #eee;
}

.ibox-child .ibox-head, .ibox-child .ibox-body {
    padding: 10px;
}

body.fullscreen-mode {
    overflow-y: hidden;
}

.nav-pills, .nav-tabs {
    margin-bottom: 15px;
}

.nav-pills .nav-link, .nav-tabs .nav-link {
    color: inherit;
}

.nav-tabs .nav-link {
    -webkit-border-radius: 2px 2px 0 0;
    border-radius: 2px 2px 0 0;
    margin-right: 2px;
    padding: .7rem 1rem;
    background-color: #eee;
}

.nav-tabs .nav-link:disabled {
    cursor: not-allowed;
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus, .nav-tabs .nav-link.active {
    color: #333;
}

.tab-content {
    padding-top: 15px;
}

.tabs-line>li .nav-link {
    -webkit-transition: all .25s;
    -o-transition: all .25s;
    transition: all .25s;
    border-bottom: 3px solid transparent;
}

.tabs-line>li .nav-link:hover {
    border-color: transparent;
    background-color: transparent;
    border-bottom-color: #cce5f6 !important;
}

.tabs-line>li .nav-link.active, .tabs-line>li .nav-link.active:hover, .tabs-line>li .nav-link.active:focus {
    border-color: transparent;
    border-bottom: 3px solid #52a7e0 !important;
}

.tabs-left, .tabs-right, .tabs-line-left, .tabs-line-right {
    border-bottom: 0;
}

.tabs-line-left {
    float: left;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-right: 20px;
}

.tabs-line-left>li>.nav-link, .tabs-line-left>li>.nav-link:focus {
    border-right: 1px solid #ddd;
}

.tabs-line-left>li>.nav-link:hover {
    border-color: transparent;
    border-right: 3px solid #caf0f2;
    padding-right: 13px;
    margin-right: -1px;
}

.tabs-line-left>li>.nav-link.active, .tabs-line-left>li>.nav-link.active:hover, .tabs-line-left>li>.nav-link.active:focus {
    border: 1px solid transparent;
    border-right: 3px solid #4ccdd3;
    padding-right: 13px;
    margin-right: -1px;
}

.tabs-line-right {
    float: right;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-left: 20px;
}

.tabs-line-right>li>.nav-link, .tabs-line-right>li>.nav-link:focus {
    border-left: 1px solid #ddd;
}

.tabs-line-right>li>.nav-link:hover {
    border-color: transparent;
    border-left: 3px solid #caf0f2;
    padding-left: 13px;
    margin-left: -1px;
}

.tabs-line-right>li>.nav-link.active, .tabs-line-right>li>.nav-link.active:hover, .tabs-line-right>li>.nav-link.active:focus {
    border: 1px solid transparent;
    border-left: 3px solid #4ccdd3;
    padding-left: 13px;
    margin-left: -1px;
}

.tabs-left {
    float: left;
    margin-right: 20px;
}

.tabs-left .nav-link {
    -webkit-border-radius: 2px 0 0 2px;
    border-radius: 2px 0 0 2px;
    margin-right: -1px;
    border-right: 1px solid #ddd;
}

.tabs-left .nav-link.active, .tabs-left .nav-link.active:hover, .tabs-left .nav-link.active:focus {
    border-color: #ddd transparent #ddd #ddd;
}

.tabs-right {
    float: right;
    margin-left: 20px;
}

.tabs-right .nav-link {
    -webkit-border-radius: 0 2px 2px 0;
    border-radius: 0 2px 2px 0;
    margin-left: -1px;
    margin-right: 0;
    border-left: 1px solid #ddd;
}

.tabs-right .nav-link.active, .tabs-right .nav-link.active:hover, .tabs-right .nav-link.active:focus {
    border-color: #ddd #ddd #ddd transparent;
}

.tabs-below {
    margin-bottom: 0;
    margin-top: 10px;
    border-top: 1px solid #ddd;
    border-bottom: 0;
}

.tabs-below .nav-item {
    margin-bottom: 0;
    margin-top: -1px;
}

.tabs-below .nav-link.active {
    border-color: transparent #ddd #ddd !important;
}

.nav-pills .nav-link.active, .nav-pills .nav-link.active:hover, .nav-pills .nav-link.active:focus {
    background-color: #2CC4CB !important;
    color: #fff;
}

.nav-pills .nav-link {
    -webkit-border-radius: 2px;
    border-radius: 2px;
}

.alert {
    -webkit-border-radius: 0px;
    border-radius: 0px;
}

.alert h4 {
    margin-bottom: 8px;
}

.alert-link {
    font-weight: 600;
}

.alert-success {
    background-color: #97e6b8;
    border-color: #58d68d;
    color: #219351;
}

.alert-success hr {
    border-top-color: #44d17f;
}

.alert-success .alert-link {
    color: #18693a;
}

.alert-success.alert-bordered {
    border-left: 4px solid #2cb566;
}

.alert-success.alert-bordered-right {
    border-right: 4px solid #2cb566;
}

.alert-info {
    background-color: #91dbf2;
    border-color: #4fc5ea;
    color: #1a89ac;
}

.alert-info hr {
    border-top-color: #38bde7;
}

.alert-info .alert-link {
    color: #136680;
}

.alert-info.alert-bordered {
    border-left: 4px solid #19a7d3;
}

.alert-info.alert-bordered-right {
    border-right: 4px solid #19a7d3;
}

.alert-warning {
    background-color: #f9ce89;
    border-color: #f5b041;
    color: #af700d;
}

.alert-warning hr {
    border-top-color: #f4a629;
}

.alert-warning .alert-link {
    color: #805209;
}

.alert-warning.alert-bordered {
    border-left: 4px solid #de8d0c;
}

.alert-warning.alert-bordered-right {
    border-right: 4px solid #de8d0c;
}

.alert-danger {
    background-color: #f3a69e;
    border-color: #ec7063;
    color: #a6372b;
}

.alert-danger hr {
    border-top-color: #e95b4c;
}

.alert-danger .alert-link {
    color: #7d2a21;
}

.alert-danger.alert-bordered {
    border-left: 4px solid #e4311f;
}

.alert-danger.alert-bordered-right {
    border-right: 4px solid #e4311f;
}

.badge, .label {
    padding: 3px 6px;
    line-height: inherit;
    font-size: 11px;
    font-weight: 600;
    color: #fff;
    -webkit-border-radius: 2px;
    border-radius: 2px;
}

.badge-pill, .label-pill {
    -webkit-border-radius: 10px !important;
    border-radius: 10px !important;
}

.badge-default, .label-default {
    background-color: #e3e6e7;
    color: #333;
}

.badge-primary, .label-primary {
    background-color: #3498db;
}

.badge-success, .label-success {
    background-color: #2ecc71;
}

.badge-info, .label-info {
    background-color: #23B7E5;
}

.badge-warning, .label-warning {
    background-color: #F39C12;
}

.badge-danger, .label-danger {
    background-color: #e74c3c;
}

.badge-circle, .label-circle {
    height: 20px;
    min-width: 20px;
    line-height: 20px;
    padding: 0;
    -webkit-border-radius: 10px;
    border-radius: 10px;
}

.badge-big {
    display: inline-block;
    padding: 0;
    text-align: center;
    width: 30px;
    height: 30px;
    line-height: 29px;
    font-size: 15px;
}

.progress {
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    height: auto;
}

.progress .progress-bar {
    height: 16px;
    line-height: 16px;
    background-color: #3498db;
}

.progress .progress-bar.progress-bar-primary {
    background-color: #3498db;
}

.progress .progress-bar.progress-bar-success {
    background-color: #2ecc71;
}

.progress .progress-bar.progress-bar-info {
    background-color: #23B7E5;
}

.progress .progress-bar.progress-bar-warning {
    background-color: #F39C12;
}

.progress .progress-bar.progress-bar-danger {
    background-color: #e74c3c;
}

.progress.progress-vertical {
    display: inline-block;
    position: relative;
    height: 100%;
    width: 16px;
    margin-right: 20px;
}

.progress.progress-vertical .progress-bar {
    width: 100%;
}

.progress.progress-vertical.progress-bottom .progress-bar {
    position: absolute;
    bottom: 0;
}

.progress.progress-vertical.progress-wide {
    width: 36px;
}

.dropdown-menu {
    -webkit-border-radius: 2px;
    border-radius: 2px;
    padding: 0;
    color: inherit;
}

.dropdown-menu .dropdown-item, .dropdown-menu>li>a {
    padding: 8px 15px;
    color: inherit;
}

.dropdown-menu .dropdown-item:hover, .dropdown-menu .dropdown-item:focus, .dropdown-menu>li>a:hover, .dropdown-menu>li>a:focus {
    background-color: #f7f8f8;
    color: #16181b;
}

.dropdown-menu .active>a, .dropdown-menu .active>a:hover, .dropdown-menu .active>a:focus, .dropdown-menu .dropdown-item.active, .dropdown-menu .dropdown-item.active:hover, .dropdown-menu .dropdown-item.active:focus {
    background-color: #ebedee;
    color: inherit;
}

.dropdown-menu>li>a {
    display: block;
    width: 100%;
    clear: both;
    font-weight: 400;
    text-align: inherit;
    white-space: nowrap;
    background: 0 0;
    border: 0;
}

.dropdown-toggle::after {
    display: none;
}

.dropdown-menu-right {
    right: 0;
    left: auto;
}

.list-group .list-group-item {
    border-color: transparent;
    background-color: transparent;
    padding: 10px 15px;
}

.list-group .list-group-item.disabled {
    background-color: transparent;
    color: #ccc;
}

.list-group .list-group-item.active {
    background-color: transparent;
    border-color: transparent;
    color: #2ecc71;
}

.list-group.list-group-full .list-group-item {
    padding-left: 0;
    padding-right: 0;
}

.list-group.list-group-divider .list-group-item:not(:first-child) {
    border-top-color: #e1eaec;
}

.list-group.list-group-bordered .list-group-item {
    border-color: #e1eaec;
}

.list-group.list-group-bordered .list-group-item.active {
    background-color: #3498db;
    border-color: #3498db;
    color: #fff;
}

.list-group.list-group-bordered .list-group-item:first-child {
    -webkit-border-top-left-radius: 3px;
    border-top-left-radius: 3px;
    -webkit-border-top-right-radius: 3px;
    border-top-right-radius: 3px;
}

.list-group.list-group-bordered .list-group-item:last-child {
    -webkit-border-bottom-left-radius: 3px;
    border-bottom-left-radius: 3px;
    -webkit-border-bottom-right-radius: 3px;
    border-bottom-right-radius: 3px;
}

.list-group .list-group-item-success {
    color: #219351;
    background-color: #e0f7ea;
}

.list-group a.list-group-item-success, .list-group button.list-group-item-success {
    color: #219351;
}

.list-group a.list-group-item-success:hover, .list-group a.list-group-item-success:focus, .list-group button.list-group-item-success:hover, .list-group button.list-group-item-success:focus {
    color: #219351;
    background-color: #ccf2dc;
}

.list-group .list-group-item-info {
    color: #1984a5;
    background-color: #def4fb;
}

.list-group a.list-group-item-info, .list-group button.list-group-item-info {
    color: #1984a5;
}

.list-group a.list-group-item-info:hover, .list-group a.list-group-item-info:focus, .list-group button.list-group-item-info:hover, .list-group button.list-group-item-info:focus {
    color: #1984a5;
    background-color: #c7ecf8;
}

.list-group .list-group-item-warning {
    color: #af700d;
    background-color: #fdf0db;
}

.list-group a.list-group-item-warning, .list-group button.list-group-item-warning {
    color: #af700d;
}

.list-group a.list-group-item-warning:hover, .list-group a.list-group-item-warning:focus, .list-group button.list-group-item-warning:hover, .list-group button.list-group-item-warning:focus {
    color: #af700d;
    background-color: #fce6c3;
}

.list-group .list-group-item-danger {
    color: #a6372b;
    background-color: #fbe4e2;
}

.list-group a.list-group-item-danger, .list-group button.list-group-item-danger {
    color: #a6372b;
}

.list-group a.list-group-item-danger:hover, .list-group a.list-group-item-danger:focus, .list-group button.list-group-item-danger:hover, .list-group button.list-group-item-danger:focus {
    color: #a6372b;
    background-color: #f8cfcc;
}

.media {
    margin: 0;
}

.media .media-heading {
    line-height: 1.1;
    font-weight: 600;
    margin-bottom: 5px;
}

.media .media-img {
    padding-right: 14px;
}

.media-list {
    padding: 0;
    list-style: none;
}

.media-list .media {
    padding: 8px 0;
}

.media-right .media-img {
    padding-left: 14px;
    padding-right: 0;
}

.media-list.media-list-divider .media:not(:first-child) {
    border-top: 1px solid #e1eaec;
}

.card-title {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 10px;
    margin-top: 0;
}

.card-subtitle {
    margin-top: -10px;
    margin-bottom: 10px;
    font-size: 13px;
}

.card-header, .card-footer {
    background-color: inherit;
}

.form-group {
    margin-bottom: 1rem;
}

.form-text {
    display: block;
    margin-top: 1rem;
}

.form-inline {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
}

/* .form-control {
    -webkit-border-radius: 2px;
    border-radius: 2px;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
} */

.form-control:focus {
    border-color: #23B7E5;
}

select.form-control {
    -webkit-appearance: listbox;
}

.input-rounded {
    -webkit-border-radius: 200px;
    border-radius: 200px;
}

.input-group-addon {
    color: #5d6064;
    color: #71808f;
    -webkit-border-radius: 2px !important;
    border-radius: 2px !important;
}

.help-block {
    display: block;
    font-size: 13px;
    margin-bottom: 0;
    margin-top: 2px;
}

.input-group-icon {
    position: relative;
    color: #71808f;
}

.input-group-icon .form-control {
    padding-left: 33px;
}

.input-group-icon .input-icon {
    position: absolute;
    left: 0;
    top: 0;
    height: 33px;
    line-height: 33px;
    width: 34px;
    text-align: center;
}

.input-group-icon.right .input-icon {
    right: 0;
    left: auto;
}

.input-group-icon.right .form-control {
    padding-left: 12px;
    padding-right: 33px;
}

.has-success .form-control, .has-success .form-control:focus {
    border-color: #2ecc71;
}

.has-success .control-label, .has-success .col-form-label, .has-success .input-icon>i {
    color: #2ecc71;
}

.has-warning .form-control, .has-warning .form-control:focus {
    border-color: #F39C12;
}

.has-warning .control-label, .has-warning .col-form-label, .has-warning .input-icon>i {
    color: #F39C12;
}

.has-error .form-control, .has-error .form-control:focus {
    border-color: #e74c3c;
}

.has-error .control-label, .has-error .col-form-label, .has-error .help-block, .has-error .error, .has-error .input-icon>i {
    color: #e74c3c;
}

.help-block.error {
    color: #e74c3c;
}

.ui-checkbox, .ui-radio {
    position: relative;
    margin-bottom: 0;
    cursor: pointer;
    padding-left: 28px;
    font-weight: 400;
    min-height: 20px;
}

.ui-checkbox .input-span, .ui-radio .input-span {
    position: absolute;
    display: inline-block;
    height: 20px;
    width: 20px;
    left: 0;
    border: 1px solid #ccc;
    -webkit-border-radius: 4px;
    border-radius: 4px;
}

.ui-checkbox .input-span::after, .ui-radio .input-span::after {
    content: '';
    position: absolute;
    display: none;
    width: 5px;
    height: 10px;
    left: 7px;
    top: 3px;
    border: solid #888;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.ui-checkbox input, .ui-radio input {
    position: absolute;
    opacity: 0;
}

.ui-checkbox input:checked~.input-span:after, .ui-radio input:checked~.input-span:after {
    display: block;
}

.ui-checkbox:hover .input-span, .ui-radio:hover .input-span {
    border-color: #b8b8b8;
}

.ui-checkbox.ui-checkbox-gray .input-span, .ui-checkbox.ui-radio-gray .input-span, .ui-radio.ui-checkbox-gray .input-span, .ui-radio.ui-radio-gray .input-span {
    background-color: #E6E6E6;
    border-color: transparent;
}

.ui-checkbox.ui-checkbox-gray .input-span::after, .ui-checkbox.ui-radio-gray .input-span::after, .ui-radio.ui-checkbox-gray .input-span::after, .ui-radio.ui-radio-gray .input-span::after {
    border-color: #888;
}

.ui-checkbox.disabled, .ui-radio.disabled {
    opacity: .7;
    cursor: not-allowed;
}

.ui-checkbox.check-single, .ui-radio.check-single {
    padding-left: 18px;
}

.ui-radio .input-span {
    -webkit-border-radius: 50%;
    border-radius: 50%;
}

.ui-radio .input-span::after {
    border: 0;
    height: 6px;
    left: 6px;
    top: 6px;
    width: 6px;
    background: #888;
    -webkit-border-radius: 100%;
    border-radius: 100%;
}

.check-list .ui-checkbox, .check-list .ui-radio {
    display: block;
}

.check-list .ui-checkbox+.ui-checkbox, .check-list .ui-radio+.ui-radio {
    margin-top: 8px;
}

.ui-checkbox-inline, .ui-radio-inline {
    display: inline-block;
    margin-right: 15px;
    margin-top: 5px;
}

.ui-checkbox-primary input:checked~.input-span {
    background-color: #3498db;
    border-color: #3498db;
}

.ui-checkbox-primary input:checked~.input-span::after {
    border-color: #fff;
}

.ui-radio-primary .input-span, .ui-radio-primary:hover .input-span {
    border-color: #3498db;
}

.ui-radio-primary input:checked~.input-span {
    background-color: #3498db;
}

.ui-radio-primary input:checked~.input-span::after {
    background-color: #fff;
}

.ui-checkbox-success input:checked~.input-span {
    background-color: #2ecc71;
    border-color: #2ecc71;
}

.ui-checkbox-success input:checked~.input-span::after {
    border-color: #fff;
}

.ui-radio-success .input-span, .ui-radio-success:hover .input-span {
    border-color: #2ecc71;
}

.ui-radio-success input:checked~.input-span {
    background-color: #2ecc71;
}

.ui-radio-success input:checked~.input-span::after {
    background-color: #fff;
}

.ui-checkbox-info input:checked~.input-span {
    background-color: #23B7E5;
    border-color: #23B7E5;
}

.ui-checkbox-info input:checked~.input-span::after {
    border-color: #fff;
}

.ui-radio-info .input-span, .ui-radio-info:hover .input-span {
    border-color: #23B7E5;
}

.ui-radio-info input:checked~.input-span {
    background-color: #23B7E5;
}

.ui-radio-info input:checked~.input-span::after {
    background-color: #fff;
}

.ui-checkbox-warning input:checked~.input-span {
    background-color: #F39C12;
    border-color: #F39C12;
}

.ui-checkbox-warning input:checked~.input-span::after {
    border-color: #fff;
}

.ui-radio-warning .input-span, .ui-radio-warning:hover .input-span {
    border-color: #F39C12;
}

.ui-radio-warning input:checked~.input-span {
    background-color: #F39C12;
}

.ui-radio-warning input:checked~.input-span::after {
    background-color: #fff;
}

.ui-checkbox-danger input:checked~.input-span {
    background-color: #e74c3c;
    border-color: #e74c3c;
}

.ui-checkbox-danger input:checked~.input-span::after {
    border-color: #fff;
}

.ui-radio-danger .input-span, .ui-radio-danger:hover .input-span {
    border-color: #e74c3c;
}

.ui-radio-danger input:checked~.input-span {
    background-color: #e74c3c;
}

.ui-radio-danger input:checked~.input-span::after {
    background-color: #fff;
}

.table>tbody>tr>th, .table>tbody>tr>td, .table>tfoot>tr>th, .table>tfoot>tr>td {
    border-top: 1px solid #e8e8e8;
}

.table thead th {
    border-bottom: 1px solid #ddd !important;
    border-top: 0;
    font-weight: 700;
}

.table.no-border>tbody>tr>td, .table.no-border>thead>tr>th {
    border: 0 none !important;
}

.table-bordered {
    border: 1px solid #e8e8e8;
}

.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.04);
}

.table-hover tbody tr:hover {
    background-color: rgba(0, 0, 0, 0.05);
}

.table tr.success>td {
    background-color: #e0f7ea !important;
}

.table tr.info>td {
    background-color: #def4fb !important;
}

.table tr.warning>td {
    background-color: #fdf0db !important;
}

.table tr.danger>td {
    background-color: #fbe4e2 !important;
}

.pagination {
    display: flex !important;
    margin: 0 !important;
}

.pagination .page-link {
    color: inherit;
}

.pagination .page-link:hover, .pagination .page-link:focus {
    color: #3498db;
}

.pagination .page-item.active .page-link, .pagination .active>a, .pagination .active>a:hover, .pagination .active>a:focus, .pagination .active>span, .pagination .active>span:hover, .pagination .active>span:focus {
    background-color: #3498db;
    border-color: #3498db;
    color: #fff;
}

.modal-dialog {
    z-index: 1100;
}

.modal-dialog .modal-content {
    border: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
    -webkit-box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.14), 0 4px 5px 0 rgba(0, 0, 0, 0.098), 0 1px 10px 0 rgba(0, 0, 0, 0.084);
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.14), 0 4px 5px 0 rgba(0, 0, 0, 0.098), 0 1px 10px 0 rgba(0, 0, 0, 0.084);
}

.modal-header .close {
    margin: 0;
    font-size: 0;
    font-family: 'themify';
    speak: none;
    font-style: normal;
    font-variant: normal;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    opacity: .5;
    cursor: pointer;
}

.modal-header .close::before {
    content: "\e646";
    font-size: 16px;
}

.modal-header .close:hover {
    opacity: .75;
}

.modal-header .close span {
    display: none;
}

.img-circle {
    -webkit-border-radius: 50%;
    border-radius: 50%;
}

/*
*  SOCIAL WIDGETS
*/

.social-widget {
    width: 100px;
    display: inline-block;
}

.social-widget-header {
    padding: 15px;
    text-align: center;
    font-size: 36px;
    color: #fff;
}

.social-widget-count {
    padding: 12px;
    font-weight: 500;
    font-size: 18px;
    color: #fff;
    text-align: center;
}

.social-widget-facebook .social-widget-header {
    background-color: #3B5998;
}

.social-widget-facebook .social-widget-count {
    background-color: #4264aa;
}

.social-widget-twitter .social-widget-header {
    background-color: #00ACED;
}

.social-widget-twitter .social-widget-count {
    background-color: #08bbff;
}

.social-widget-google .social-widget-header {
    background-color: #F86C6B;
}

.social-widget-google .social-widget-count {
    background-color: #f98483;
}

.social-widget-linkedin .social-widget-header {
    background-color: #4875b4;
}

.social-widget-linkedin .social-widget-count {
    background-color: #5982bd;
}

.social-widget-pinterest .social-widget-header {
    background-color: #EC488D;
}

.social-widget-pinterest .social-widget-count {
    background-color: #ee5f9b;
}

.social-widget-vk .social-widget-header {
    background-color: #4B73A4;
}

.social-widget-vk .social-widget-count {
    background-color: #5780b2;
}

/*
* STAT WIDGET
*/

.widget-stat .ibox-body {
    padding: 12px 15px;
}

.widget-stat-icon {
    position: absolute;
    top: 0;
    right: 0;
    width: 60px;
    height: 100%;
    line-height: 100px;
    text-align: center;
    font-size: 30px;
    background-color: rgba(0, 0, 0, 0.1);
}

/*
*
*/

.static-widget {
    display: inline-block;
    width: 120px;
    height: 120px;
    text-align: center;
    padding: 15px;
}

.static-widget i {
    display: inline-block;
    margin-bottom: 15px;
    font-size: 36px;
}

.widget-dark-progress {
    background: rgba(0, 0, 0, 0.1);
}

.widget-dark-progress .progress-bar {
    background-color: #e9ecef;
}

.widget-dark-badge {
    background: rgba(0, 0, 0, 0.2);
}

/*
* Tasks list
*/

.tasks-list .task-data {
    padding-left: 25px;
}

.tasks-list .task-item .ui-checkbox input:checked~.task-title {
    text-decoration: line-through;
}

.task-actions {
    display: none;
    position: absolute;
    right: 0;
    top: 50%;
    margin-top: -15px;
}

.task-actions>a.dropdown-toggle {
    color: #aaa;
    height: 30px;
    width: 30px;
    display: inline-block;
    line-height: 30px;
    text-align: center;
}

.task-item:hover .task-actions {
    display: block;
}

@-webkit-keyframes collapseMenu {
    from {
        width: 60px;
    }

    to {
        width: 100%;
    }
}

@-webkit-keyframes fadeInUp {
    0% {
        opacity: 0;
        -webkit-transform: translateY(20px);
        transform: translateY(20px);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}

@-webkit-keyframes heartbit {
    0% {
        -webkit-transform: scale(0);
        opacity: 0.0;
    }

    25% {
        -webkit-transform: scale(0.1);
        opacity: 0.1;
    }

    50% {
        -webkit-transform: scale(0.5);
        opacity: 0.3;
    }

    75% {
        -webkit-transform: scale(0.8);
        opacity: 0.5;
    }

    100% {
        -webkit-transform: scale(1);
        opacity: 0.0;
    }
}

@-webkit-keyframes loader-rotate {
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.fade-in-up {
    -webkit-animation: fadeInUp .5s;
    animation: fadeInUp .5s;
}

/************************************************************************************
smaller than 980
*************************************************************************************/

@media screen and (max-width: 980px) {

    /* embedded videos */
    .video embed, .video object, .video iframe {
        width: 100%;
        height: auto;
        min-height: 300px;
    }
}

/************************************************************************************
smaller than 768
*************************************************************************************/

@media screen and (max-width: 768px) {
    body:not(.sidebar-mini):not(.drawer-sidebar) .dashboard-wrapper {
        margin-left: 0;
    }
}

/************************************************************************************
smaller than 650
*************************************************************************************/

@media screen and (max-width: 650px) {

    /* embedded videos */
    .video embed, .video object, .video iframe {
        min-height: 250px;
    }

    .d-flex {
        flex-direction: column;
    }

    .ibox .ibox-head {
        flex-direction: column;
        padding: 5px 20px;
        height: 70px;
    }
}

/************************************************************************************
smaller than 560
*************************************************************************************/

.bg-blue-800 {
    background-color: #256d9e !important;
}

.bg-blue-700 {
    background-color: #2b7db4 !important;
}

.bg-blue-600 {
    background-color: #308cc9 !important;
}

.bg-blue {
    background-color: #3498db !important;
}

.bg-blue-400 {
    background-color: #52a7e0 !important;
}

.bg-blue-300 {
    background-color: #71b7e6 !important;
}

.bg-blue-200 {
    background-color: #9acced !important;
}

.bg-blue-100 {
    background-color: #c2e0f4 !important;
}

.bg-blue-50 {
    background-color: #e7f3fb !important;
}

.bg-blue-light-800 {
    background-color: #1984a5 !important;
}

.bg-blue-light-700 {
    background-color: #1d96bc !important;
}

.bg-blue-light-600 {
    background-color: #20a8d3 !important;
}

.bg-blue-light {
    background-color: #23B7E5 !important;
}

.bg-blue-light-400 {
    background-color: #44c2e9 !important;
}

.bg-blue-light-300 {
    background-color: #65cded !important;
}

.bg-blue-light-200 {
    background-color: #91dbf2 !important;
}

.bg-blue-light-100 {
    background-color: #bde9f7 !important;
}

.bg-blue-light-50 {
    background-color: #e5f6fc !important;
}

.bg-green-800 {
    background-color: #219351 !important;
}

.bg-green-700 {
    background-color: #26a75d !important;
}

.bg-green-600 {
    background-color: #2abc68 !important;
}

.bg-green {
    background-color: #2ecc71 !important;
}

.bg-green-400 {
    background-color: #4dd486 !important;
}

.bg-green-300 {
    background-color: #6ddb9c !important;
}

.bg-green-200 {
    background-color: #97e6b8 !important;
}

.bg-green-100 {
    background-color: #c0f0d4 !important;
}

.bg-green-50 {
    background-color: #e6f9ee !important;
}

.bg-orange-800 {
    background-color: #af700d !important;
}

.bg-orange-700 {
    background-color: #c7800f !important;
}

.bg-orange-600 {
    background-color: #e09011 !important;
}

.bg-orange {
    background-color: #F39C12 !important;
}

.bg-orange-400 {
    background-color: #f5ab36 !important;
}

.bg-orange-300 {
    background-color: #f7ba59 !important;
}

.bg-orange-200 {
    background-color: #f9ce89 !important;
}

.bg-orange-100 {
    background-color: #fbe1b8 !important;
}

.bg-orange-50 {
    background-color: #fef3e3 !important;
}

.bg-red-800 {
    background-color: #a6372b !important;
}

.bg-red-700 {
    background-color: #bd3e31 !important;
}

.bg-red-600 {
    background-color: #d54637 !important;
}

.bg-red {
    background-color: #e74c3c !important;
}

.bg-red-400 {
    background-color: #eb6759 !important;
}

.bg-red-300 {
    background-color: #ee8277 !important;
}

.bg-red-200 {
    background-color: #f3a69e !important;
}

.bg-red-100 {
    background-color: #f8c9c5 !important;
}

.bg-red-50 {
    background-color: #fceae8 !important;
}

.bg-silver-800 {
    background-color: #888c8f !important;
}

.bg-silver-700 {
    background-color: #9ba0a3 !important;
}

.bg-silver-600 {
    background-color: #aeb3b7 !important;
}

.bg-silver {
    background-color: #bdc3c7 !important;
}

.bg-silver-400 {
    background-color: #c7cccf !important;
}

.bg-silver-300 {
    background-color: #d1d5d8 !important;
}

.bg-silver-200 {
    background-color: #dee1e3 !important;
}

.bg-silver-100 {
    background-color: #ebedee !important;
}

.bg-silver-50 {
    background-color: #f7f8f8 !important;
}

.bg-ebony-800 {
    background-color: #253544 !important;
}

.bg-ebony-700 {
    background-color: #2b3c4e !important;
}

.bg-ebony-600 {
    background-color: #304357 !important;
}

.bg-ebony {
    background-color: #34495f !important;
}

.bg-ebony-400 {
    background-color: #526477 !important;
}

.bg-ebony-300 {
    background-color: #71808f !important;
}

.bg-ebony-200 {
    background-color: #9aa4af !important;
}

.bg-ebony-100 {
    background-color: #c2c8cf !important;
}

.bg-ebony-50 {
    background-color: #e7e9ec !important;
}

.bg-purple-800 {
    background-color: #5b3f8c !important;
}

.bg-purple-700 {
    background-color: #67479f !important;
}

.bg-purple-600 {
    background-color: #7450b2 !important;
}

.bg-purple {
    background-color: #7E57C2 !important;
}

.bg-purple-400 {
    background-color: #9170cb !important;
}

.bg-purple-300 {
    background-color: #a589d4 !important;
}

.bg-purple-200 {
    background-color: #bfabe1 !important;
}

.bg-purple-100 {
    background-color: #d8cded !important;
}

.bg-purple-50 {
    background-color: #f0ebf8 !important;
}

.bg-grey-800 {
    background-color: #5b6566 !important;
}

.bg-grey-700 {
    background-color: #687374 !important;
}

.bg-grey-600 {
    background-color: #758182 !important;
}

.bg-grey {
    background-color: #7f8c8d !important;
}

.bg-grey-400 {
    background-color: #929d9e !important;
}

.bg-grey-300 {
    background-color: #a5afaf !important;
}

.bg-grey-200 {
    background-color: #bfc6c6 !important;
}

.bg-grey-100 {
    background-color: #d9dddd !important;
}

.bg-grey-50 {
    background-color: #f0f1f1 !important;
}

.bg-teal-800 {
    background-color: #138770 !important;
}

.bg-teal-700 {
    background-color: #159a80 !important;
}

.bg-teal-600 {
    background-color: #18ad90 !important;
}

.bg-teal {
    background-color: #1abc9c !important;
}

.bg-teal-400 {
    background-color: #3cc6ab !important;
}

.bg-teal-300 {
    background-color: #5fd0ba !important;
}

.bg-teal-200 {
    background-color: #8ddece !important;
}

.bg-teal-100 {
    background-color: #baebe1 !important;
}

.bg-teal-50 {
    background-color: #e4f7f3 !important;
}

.bg-yellow-800 {
    background-color: #ae8d0b !important;
}

.bg-yellow-700 {
    background-color: #c6a10c !important;
}

.bg-yellow-600 {
    background-color: #deb40e !important;
}

.bg-yellow {
    background-color: #f1c40f !important;
}

.bg-yellow-400 {
    background-color: #f3cd33 !important;
}

.bg-yellow-300 {
    background-color: #f5d657 !important;
}

.bg-yellow-200 {
    background-color: #f8e287 !important;
}

.bg-yellow-100 {
    background-color: #fbedb7 !important;
}

.bg-yellow-50 {
    background-color: #fdf8e2 !important;
}

.bg-pink-800 {
    background-color: #a81647 !important;
}

.bg-pink-700 {
    background-color: #bf1951 !important;
}

.bg-pink-600 {
    background-color: #d61c5b !important;
}

.bg-pink {
    background-color: #E91E63 !important;
}

.bg-pink-400 {
    background-color: #ec407a !important;
}

.bg-pink-300 {
    background-color: #f06292 !important;
}

.bg-pink-200 {
    background-color: #f48fb1 !important;
}

.bg-pink-100 {
    background-color: #f8bcd0 !important;
}

.bg-pink-50 {
    background-color: #fce4ec !important;
}

.bg-white {
    background-color: #fff !important;
}

.bg-transparent {
    background-color: transparent !important;
}

.bg-primary {
    background-color: #3498db !important;
}

.bg-success {
    background-color: #2ecc71 !important;
}

.bg-info {
    background-color: #23B7E5 !important;
}

.bg-warning {
    background-color: #F39C12 !important;
}

.bg-danger {
    background-color: #e74c3c !important;
}

.no-margin {
    margin: 0 !important;
}

.no-padding {
    padding: 0 !important;
}

.m-t-0 {
    margin-top: 0px !important;
}

.m-t-5 {
    margin-top: 5px !important;
}

.m-t-10 {
    margin-top: 10px !important;
}

.m-t-15 {
    margin-top: 15px !important;
}

.m-t-20 {
    margin-top: 20px !important;
}

.m-b-0 {
    margin-bottom: 0px !important;
}

.m-b-5 {
    margin-bottom: 5px !important;
}

.m-b-10 {
    margin-bottom: 10px !important;
}

.m-b-15 {
    margin-bottom: 15px !important;
}

.m-b-20 {
    margin-bottom: 20px !important;
}

.m-r-5 {
    margin-right: 5px !important;
}

.m-r-10 {
    margin-right: 10px !important;
}

.m-r-15 {
    margin-right: 15px !important;
}

.m-r-20 {
    margin-right: 20px !important;
}

.m-l-5 {
    margin-left: 5px !important;
}

.m-l-10 {
    margin-left: 10px !important;
}

.m-l-15 {
    margin-left: 15px !important;
}

.m-l-20 {
    margin-left: 20px !important;
}

.p-b-5 {
    padding-bottom: 5px !important;
}

.p-b-10 {
    padding-bottom: 10px !important;
}

.p-b-20 {
    padding-bottom: 20px !important;
}

.p-r-5 {
    padding-right: 5px !important;
}

.p-r-10 {
    padding-right: 10px !important;
}

.p-r-20 {
    padding-right: 20px !important;
}

.p-l-5 {
    padding-left: 5px !important;
}

.p-l-10 {
    padding-left: 10px !important;
}

.p-l-20 {
    padding-left: 20px !important;
}

.p-t-5 {
    padding-top: 5px !important;
}

.p-t-10 {
    padding-top: 10px !important;
}

.p-t-20 {
    padding-top: 20px !important;
}

.font-13 {
    font-size: 13px;
}

.font-14 {
    font-size: 14px;
}

.font-15 {
    font-size: 15px;
}

.font-16 {
    font-size: 16px;
}

.font-17 {
    font-size: 17px;
}

.font-18 {
    font-size: 18px;
}

.font-20 {
    font-size: 20px;
}

.font-26 {
    font-size: 26px;
}

.font-light {
    font-weight: 300 !important;
}

.font-normal {
    font-weight: 400 !important;
}

.font-strong {
    font-weight: 600 !important;
}

.font-bold {
    font-weight: 700 !important;
}

.link-blue {
    color: inherit;
}

.link-blue:hover, .link-blue:focus {
    color: #3498db !important;
}

.link-green {
    color: inherit;
}

.link-green:hover, .link-green:focus {
    color: #2ecc71 !important;
}

.color-white {
    color: #fff !important;
}

.color-green {
    color: #2ecc71 !important;
}

.color-blue {
    color: #3498db !important;
}

.color-orange {
    color: #F39C12 !important;
}

.color-red {
    color: #e74c3c !important;
}

.color-blue-light {
    color: #23B7E5 !important;
}

.color-purple {
    color: #7E57C2 !important;
}

.color-ebony {
    color: #34495f !important;
}

.color-silver {
    color: #bdc3c7 !important;
}

.color-inherit {
    color: inherit !important;
}

.text-primary {
    color: #3498db !important;
}

.text-success {
    color: #2ecc71 !important;
}

.text-info {
    color: #23B7E5 !important;
}

.text-warning {
    color: #F39C12 !important;
}

.text-danger {
    color: #e74c3c !important;
}

.text-muted {
    color: #999 !important;
}

.text-white {
    color: #fff !important;
}

a.text-primary:hover, a.text-primary:focus {
    color: #258cd1 !important;
}

a.text-info:hover, a.text-info:focus {
    color: #19a9d5 !important;
}

a.text-success:hover, a.text-success:focus {
    color: #29b765 !important;
}

a.text-warning:hover, a.text-warning:focus {
    color: #e08e0b !important;
}

a.text-danger:hover, a.text-danger:focus {
    color: #e43725 !important;
}

.uppercase {
    text-transform: uppercase !important;
}

.centered {
    float: none;
    margin-left: auto;
    margin-right: auto;
}

.rel, .p-relative {
    position: relative !important;
}

.p-absolute {
    position: absolute !important;
}

.v-middle {
    vertical-align: middle;
}

.border-0 {
    border: 0 !important;
}

.hidden {
    display: none;
}

.flexbox {
    display: -webkit-box !important;
    display: -webkit-flex !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-pack: justify !important;
    -webkit-justify-content: space-between !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
    -webkit-box-align: center !important;
    -webkit-align-items: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
}

.flexbox-b {
    display: -webkit-box !important;
    display: -webkit-flex !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-align: center !important;
    -webkit-align-items: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
}

.flex-1 {
    -webkit-box-flex: 1 !important;
    -webkit-flex: 1 !important;
    -ms-flex: 1 !important;
    flex: 1 !important;
}

/*!
 * Bootstrap v3.3.7 (http://getbootstrap.com)
 * Copyright 2011-2016 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

.header {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    /* height: 56px; */
    position: relative;
    background-color: #fff;
    margin: 0;
    border: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
    -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
}

.header .page-brand {
    width: 220px;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0 15px;
    font-weight: 500;
    font-size: 20px;
    -webkit-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    overflow: hidden;
}

.header .page-brand .brand {
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
}

.header .page-brand .brand-mini {
    display: none;
}

.header .navbar-toolbar {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.header .navbar-toolbar>li>a {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 15px;
    color: #6d7c85;
}

.header .navbar-toolbar>li .notify-signal {
    height: 6px;
    width: 6px;
    position: absolute;
    top: -3px;
    right: -3px;
    background: #18C5A9;
    -webkit-border-radius: 50%;
    border-radius: 50%;
}

.header .navbar-toolbar>li .notify-signal::before {
    display: none;
    content: '';
    position: absolute;
    top: -10px;
    right: -10px;
    height: 25px;
    width: 25px;
    z-index: 10;
    border: 5px solid #18C5A9;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    -webkit-animation: heartbit 1s ease-out;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
}

.header .navbar-toolbar>li.active .notify-signal::before {
    display: block;
}

.header .navbar-toolbar .dropdown-notification a.dropdown-toggle .notify-signal {
    background: #f75a5f;
}

.header .navbar-toolbar .dropdown-notification a.dropdown-toggle .notify-signal::before {
    border-color: #f75a5f;
}

.header .navbar-search .search-icon {
    position: absolute;
    top: 0;
    height: 100%;
    width: 40px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    color: #6d7c85;
}

.header .sidebar-toggler {
    font-size: 18px;
}

li.dropdown-user {
    padding: 0 1rem;
}

li.dropdown label#menu-label {
    cursor: pointer;
}

li.dropdown-user input#menu-toggle {
    display: none !important;
}

li.dropdown-user label#menu-label {
    display: flex;
}

li.dropdown-user label#menu-label div.user-information {
    display: flex !important;
    flex-direction: column;
    text-align: center !important;
    margin: auto 0;
    gap: 0.15rem;
}

li.dropdown-user label#menu-label img {
    width: 30px;
    margin-right: 10px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
}

li.dropdown-user ul.dropdown-menu-right {
    display: block;
    z-index: 9999;
}

input#menu-toggle:not(:checked)~ul.dropdown-menu-right {
    display: none;
}

li.dropdown-user>.dropdown-menu .dropdown-item {
    color: #6d7c85;
}

li.dropdown-user>.dropdown-menu .dropdown-item:hover {
    text-decoration: none;
}

li.dropdown-user>.dropdown-menu .dropdown-item i {
    margin-right: 10px;
}

--------------------------------------- .header .dropdown-inbox, .header .dropdown-notification {
    font-size: 15px;
}

.header .dropdown-inbox img {
    width: 40px;
    height: 40px;
}

.dropdown-menu-media {
    width: 280px;
}

.dropdown-menu-media .dropdown-menu-header {
    padding: 12px 15px;
    font-size: 13px;
    border-bottom: 1px solid #ddd;
}

.envelope-badge {
    position: absolute;
    right: 6px;
    top: 5px;
    height: 16px;
    min-width: 16px;
    line-height: 16px;
    -webkit-border-radius: 10px;
    border-radius: 10px;
    padding: 0;
}

@media screen and (max-width: 580px) {
    .header .search-toggler span, .dropdown-user>a>span {
        display: none;
    }

    .dropdown-user>a>img {
        margin-left: 0 !important;
    }
}

.page-sidebar {
    width: 220px;
    min-height: 100%;
    position: absolute;
    top: 0;
    margin-top: 50px;
    -webkit-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    z-index: 1001;
}

.page-sidebar ul {
    padding: 0;
    margin: 0;
    list-style: none;
}

.page-sidebar ul li {
    position: relative;
    display: block;
}

.page-sidebar ul li a {
    position: relative;
    display: block;
    padding: 11px 15px;
}

.page-sidebar .admin-block {
    padding: 15px 10px;
}

.page-sidebar .admin-block .admin-avatar {
    width: 45px;
}

.page-sidebar .admin-block .admin-info {
    padding-left: 1rem;
}

.side-menu .heading {
    padding: 12px 15px;
    display: block;
    font-size: 13px;
}

.side-menu li {
    outline: 0;
}

.side-menu li .sidebar-item-icon {
    display: block !important;
    float: left;
    width: 30px;
    line-height: 20px !important;
    font-size: 16px;
}

.side-menu li .nav-label {
    display: block;
}

.side-menu li .arrow {
    line-height: 18px;
    position: absolute;
    top: 50%;
    right: 15px;
    margin-top: -9px;
    opacity: 1;
}

.side-menu li.active>a>.arrow {
    -webkit-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    transform: rotate(-90deg);
}

.side-menu>li a {
    white-space: nowrap;
    overflow: hidden;
}

.side-menu .nav-2-level>li>a {
    padding: 7px 10px 7px 30px;
}

.side-menu .nav-3-level>li>a {
    padding: 7px 10px 7px 50px;
}

/*************************
  Full Close Sidebar
************************/

body:not(.fixed-layout).full-close .page-sidebar {
    width: 0px;
    display: none;
}

body:not(.fixed-layout).full-close .content-wrapper {
    margin-left: 0px !important;
}

/*************************
  MINI Sidebar
************************/

body:not(.fixed-layout).sidebar-mini .content-wrapper {
    margin-left: 60px;
}

body:not(.fixed-layout).sidebar-mini .page-sidebar {
    width: 60px;
}

body:not(.fixed-layout).sidebar-mini .page-brand {
    width: 60px;
}

body:not(.fixed-layout).sidebar-mini .brand {
    display: none;
}

body:not(.fixed-layout).sidebar-mini .brand-mini {
    display: block !important;
}

body:not(.fixed-layout).sidebar-mini .side-menu li.heading {
    display: none;
}

body:not(.fixed-layout).sidebar-mini .side-menu li .sidebar-item-icon {
    font-size: 18px;
    text-align: center;
}

body:not(.fixed-layout).sidebar-mini .side-menu li .arrow {
    display: none;
}

body:not(.fixed-layout).sidebar-mini .side-menu li .nav-2-level {
    display: none;
    position: absolute;
    top: 43px;
    left: 60px;
    min-width: 200px;
    padding-left: 0;
}

body:not(.fixed-layout).sidebar-mini .side-menu li .nav-2-level ul {
    padding-left: 0;
}

body:not(.fixed-layout).sidebar-mini .side-menu li .nav-2-level li a {
    padding-left: 20px;
}

body:not(.fixed-layout).sidebar-mini .side-menu li .nav-3-level>li>a {
    padding-left: 27px;
}

body:not(.fixed-layout).sidebar-mini .side-menu>li>a>.nav-label {
    display: none;
}

body:not(.fixed-layout).sidebar-mini .side-menu>li:hover>a {
    overflow: visible;
    width: 260px;
}

body:not(.fixed-layout).sidebar-mini .side-menu>li:hover>a>.nav-label {
    display: block;
    padding-left: 50px;
}

body:not(.fixed-layout).sidebar-mini .side-menu>li:hover .nav-2-level {
    display: block;
    height: auto !important;
    opacity: 1;
    visibility: visible;
}

body:not(.fixed-layout).sidebar-mini .admin-block {
    padding: 15px 12px;
}

body:not(.fixed-layout).sidebar-mini .admin-block .admin-info {
    display: none;
}

body.fixed-layout.sidebar-mini .page-sidebar, body.fixed-layout.sidebar-mini .page-brand {
    margin-left: -220px;
    visibility: hidden;
}

body.fixed-layout.sidebar-mini .content-wrapper {
    margin-left: 0;
}

.page-footer {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 10px 15px;
    background-color: #fff;
}

.page-wrapper {
    width: 100%;
    min-height: 100%;
    position: relative;
    overflow: hidden;
}

.content-wrapper {
    position: relative;
    background-color: #f7f7f7;
    background-color: #f2f2f2;
    background-color: #f1f1f1;
    margin-left: 220px;
    padding: 0 15px 60px 15px;
    -webkit-transition: margin .2s ease-in-out;
    -o-transition: margin .2s ease-in-out;
    transition: margin .2s ease-in-out;
    min-height: 1200px;
}

.page-header {
    padding-top: 35px;
    padding-bottom: 20px;
}

.page-content {
    padding-top: 20px;
}

.page-heading {
    padding: 0 5px;
}

.page-heading .page-title {
    font-size: 26px;
    margin: 20px 0 10px 0;
}

.page-heading .breadcrumb {
    padding: 0;
    margin: 0;
    font-size: 13px;
    background-color: inherit;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.page-heading .breadcrumb li {
    float: none;
    color: #868e96;
}

.page-heading .breadcrumb li a {
    color: inherit;
}

.page-heading .breadcrumb li+li:before {
    content: '\002022';
    color: inherit;
}

body.empty-layout .theme-config, body.empty-layout .to-top {
    display: none !important;
}

body.fixed-navbar .header, body.fixed-layout .header {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 1002;
}

body.fixed-navbar .content-wrapper, body.fixed-layout .content-wrapper {
    padding-top: 56px;
}

body.fixed-layout .page-sidebar {
    position: fixed;
    top: 56px;
    bottom: 0;
    margin-top: 0;
    padding-bottom: 100px;
}

body.boxed-layout {
    max-width: 1200px;
    margin: 0 auto;
}

body.boxed-layout.fixed-navbar .header, body.boxed-layout.fixed-layout .header {
    max-width: 1200px;
    margin: 0 auto;
}

.theme-config {
    position: absolute;
    right: -310px;
    top: 90px;
    z-index: 1001;
    width: 310px;
    -webkit-transition: right .3s ease-in-out;
    -o-transition: right .3s ease-in-out;
    transition: right .3s ease-in-out;
}

.theme-config .theme-config-toggle {
    position: absolute;
    left: -34px;
    height: 38px;
    width: 35px;
    background-color: #23b7e5;
    -webkit-border-bottom-left-radius: 4px;
    border-bottom-left-radius: 4px;
    -webkit-border-top-left-radius: 4px;
    border-top-left-radius: 4px;
    text-align: center;
    cursor: pointer;
}

.theme-config .theme-config-toggle .theme-config-show {
    font-size: 22px;
    color: #fff;
    line-height: 38px;
    -webkit-animation: fa-spin 3s infinite linear;
    animation: fa-spin 3s infinite linear;
}

.theme-config .theme-config-toggle .theme-config-close {
    display: none;
    font-size: 18px;
    line-height: 38px;
}

.theme-config .theme-config-box {
    padding: 10px 20px 20px;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.1);
}

.theme-config .theme-config-box .ui-checkbox+.ui-checkbox {
    margin-top: 15px;
}

.theme-config.opened {
    right: 0;
}

.theme-config.opened .theme-config-toggle {
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-right: none;
}

.theme-config.opened .theme-config-toggle .theme-config-show {
    display: none;
}

.theme-config.opened .theme-config-toggle .theme-config-close {
    display: block;
}

.theme-config.opened .theme-config-toggle .close-btn {
    display: block;
}

.theme-config .color-skin-box {
    position: relative;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    margin: 0 5px;
    border: 1px solid #ddd;
}

.theme-config .color-skin-box .color {
    height: 40px;
}

.theme-config .color-skin-box .color-small {
    display: block;
    height: 18px;
}

.theme-config .color-skin-box .color-check-icon {
    position: absolute;
    left: 10px;
    top: 10px;
    opacity: 0;
    font-size: 16px;
}

.theme-config .color-skin-box label {
    display: block;
    cursor: pointer;
    margin: 0;
}

.theme-config .color-skin-box input {
    position: absolute;
    opacity: 0;
    visibility: hidden;
}

.theme-config .color-skin-box input:checked+.color-check-icon {
    opacity: 1;
}

.preloader-backdrop {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1004;
    background-color: #fff;
}

.preloader-backdrop.submitting {
    background-color: #000;
    opacity: 0.4;
}

.preloader-backdrop .page-preloader {
    position: absolute;
    top: 40%;
    left: 50%;
    padding: 10px 45px 10px 10px;
    margin-left: -60px;
    z-index: 1005;
    font-family: "Poppins";
    font-size: 16px;
}

.preloader-backdrop.submitting .page-preloader {
    color: white;
}

.preloader-backdrop .page-preloader::before {
    content: '';
    position: absolute;
    top: 50%;
    right: 10px;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    margin-left: -10px;
    border-top: 2px solid #34495f;
    border-right: 2px solid transparent;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    -webkit-animation: loader-rotate 0.6s linear infinite;
    animation: loader-rotate 0.6s linear infinite;
}

.backdrop {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    background-color: rgba(0, 0, 0, 0.5) !important;
    -webkit-transition: opacity 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    -o-transition: opacity 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    transition: opacity 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    z-index: 1004;
    pointer-events: none;
}

.shined {
    z-index: 1005 !important;
}

body.has-backdrop .backdrop {
    pointer-events: auto;
    opacity: 1;
}

.to-top {
    position: fixed;
    right: 3px;
    bottom: 40px;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    color: #FFF;
    border: 2px solid #687991;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    z-index: 1001;
    opacity: 1;
    cursor: pointer;
    display: none;
}

.to-top i {
    font-size: 26px;
    color: #687991;
}

.to-top:hover {
    background-color: #687991;
}

.to-top:hover i {
    display: block;
    color: #fff;
}

.page-sidebar {
    background-color: #253544;
}

.page-sidebar .admin-block .admin-info {
    color: #fff;
}

.page-sidebar .admin-block .admin-info small {
    color: #b4bcc8;
}

.side-menu .heading {
    color: rgba(255, 255, 255, 0.4);
}

.side-menu>li a {
    color: #b4bcc8;
}

.side-menu>li a:hover, .side-menu>li a:focus {
    color: #fff;
    background-color: #3498db;
    text-decoration: none;
}

.side-menu>li.active {
    background-color: #2e4255;
}

.side-menu>li.active>a, .side-menu>li.active>a:hover, .side-menu>li.active>a:focus {
    color: #fff;
    background-color: #3498db;
}

.side-menu li a.active {
    color: #fff;
    font-weight: bold;
}

.sidebar-mini .side-menu>li:hover>a {
    background-color: #3498db;
    color: #fff;
}

.sidebar-mini .side-menu>li:hover .nav-2-level {
    background-color: #253544;
}

.header .page-brand {
    color: #fff;
    background-color: #374f65;
}

.header .page-brand a:hover {
    color: #fff;
    text-decoration: none;
}

.legendLabel {
    padding-left: 5px;
}

.flotTip {
    -webkit-border-radius: 0 !important;
    border-radius: 0 !important;
    background-color: #323232 !important;
    border: 1px solid #323232 !important;
    opacity: .8;
    color: #fff;
}

.jqstooltip {
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    border: 0 !important;
}

table.dataTable {
    width: 100% !important;
}

.fc-event {
    padding: 4px 6px;
    background-color: #3498db;
    color: #fff !important;
    border: 0;
}

#external-events .ex-event {
    padding: 5px 10px;
    margin: 5px 0;
    cursor: move;
}

.select2-container {
    max-width: 100%;
}

.select2-container--default .select2-selection--multiple, .select2-container--default .select2-selection--single, .select2-container--default.select2-container--focus .select2-selection--multiple {
    outline: 0 !important;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    height: auto;
}

.select2-dropdown {
    border: 1px solid rgba(0, 0, 0, 0.15);
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
    line-height: 30px;
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered {
    line-height: 1.25;
}

.bootstrap-timepicker-widget .bootstrap-timepicker-hour, .bootstrap-timepicker-widget .bootstrap-timepicker-meridian, .bootstrap-timepicker-widget .bootstrap-timepicker-minute, .bootstrap-timepicker-widget .bootstrap-timepicker-second {
    border: 0;
    background: 0 0;
    outline: 0 !important;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.note-editor.note-frame {
    -webkit-border-radius: 0;
    border-radius: 0;
    border-color: #ddd;
}

.note-toolbar {
    background-color: #f2f2f2 !important;
}

.note-btn, .note-btn:hover, .open .note-btn.dropdown-toggle {
    background-color: #fff;
}

.note-color .dropdown-menu>li>.btn-group {
    display: table-cell !important;
    padding: 5px;
}

.note-popover.popover {
    display: none;
}

.jvectormap-zoomin, .jvectormap-zoomout, .jvectormap-goback {
    background: #3498db;
}

.adminca-banner {
    display: block;
    padding: 15px;
    background-image: -webkit-linear-gradient(315deg, #2cc4cb, #18c5a9);
    background-image: -o-linear-gradient(315deg, #2cc4cb, #18c5a9);
    background-image: linear-gradient(135deg, #2cc4cb, #18c5a9);
    position: relative;
    max-width: 1260px;
}

.wrap-1, .wrap-2 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.adminca-banner-ribbon {
    position: absolute;
    left: 0;
    top: 5px;
    padding: 4px 10px;
    background-image: -webkit-linear-gradient(315deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.5));
    background-image: -o-linear-gradient(315deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.5));
    background-image: linear-gradient(135deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.5));
    color: #f39c12 !important;
    font-size: 1.75rem;
}

.adminca-banner-b {
    font-size: 16px;
    padding: 5px 10px;
    background: #fff;
    -webkit-box-shadow: 0 0 15px 5px rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 15px 5px rgba(0, 0, 0, 0.2);
    width: 170px;
    -webkit-border-radius: 50px;
    border-radius: 50px;
    color: #333;
}

.dev-img {
    position: absolute;
    right: 0;
    bottom: 0;
}

@media (max-width: 1230px) {
    .adminca-banner {
        display: inline-block;
    }

    .wrap-1 {
        display: block;
    }

    .wrap-2 {
        margin-bottom: 20px;
    }

    .dev-img {
        position: static;
    }

    .wrap-3 {
        -webkit-box-pack: start !important;
        -webkit-justify-content: start !important;
        -ms-flex-pack: start !important;
        justify-content: start !important;
    }
}

.solvup-green {
    color: #4C9900;
}

.solvup-red {
    color: #990000;
}

.solvup-grey {
    color: #606060;
}

.solvup-orange {
    color: #e8632e;
}

.solvup-blue {
    color: #0066cc;
}

.solvup-font-big {
    font-size: 18px
}

.dangerous-html a {
    color: rgb(255, 171, 64);
}

.card-body.dangerous-html {
    max-height: 250px;
    overflow: auto;
}

/* SCROLL START */

/* SCROLL LOOK START */

/* width */
/* ::-webkit-scrollbar {
    width: 6px;
} */

/* Track */
/* ::-webkit-scrollbar-track {
    background: #f1f1f1;
} */

/* Handle */
/* ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 80px;
} */

/* Handle on hover */
/* ::-webkit-scrollbar-thumb:hover {
    background: #555;
} */

/* SCROLL LOOK END */

.overflowY {
    overflow-y: scroll;
}

.overflowX {
    overflow-x: scroll;
}

.overflowHiddenY {
    overflow-y: hidden;
}

.overflowHiddenX {
    overflow-x: hidden;
}

/* SCROLL END */
.designSidepanel {
    max-height: 500px;
    overflow-y: scroll;
}

.move {
    position: absolute;
}

#component-design .nav.nav-tabs {
    margin-bottom: 0;
}

#component-design .nav.nav-tabs .nav-item, #component-design .nav.nav-tabs>.nav-link {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    text-align: center;
}

#component-design .nav-item .nav-link {
    width: 100%;
}

#component-design .tab-content .tab-pane {
    max-height: 640px;
    overflow-y: scroll;
}

.cursor-pointer {
    cursor: pointer;
}

.cursor-grabbing {
    cursor: grabbing;
}

.cursor-not-allowed {
    cursor: not-allowed;
}

.three-dots {
    text-overflow: ellipsis;
    overflow: hidden!important;
    white-space: nowrap;
}

.radio-list {
    padding: 5px 10px 10px 10px;
    background-color: #F0F0F0;
    border-radius: .25rem;
}

.check-list label {
    padding: 12px;
    background-color: #F0F0F0;
    border-radius: .25rem;
}

.check-list label span.input-span {
    margin-left: 10px;
}

.check-list label span.text-span {
    margin-left: 25px;
}

.drag-enter {
    opacity: 0.5;
    transform: scale(0.9);
}

.dropzone {
    height: 52px;
    border: 1px dotted #ccc;
    margin: 10px 0;
}

.white-space-normal {
    white-space: normal;
}

.tab-pane#fields .form-group,
.tab-pane#components .form-group {
    padding: 0.5rem !important;
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
    border-radius: 0.25rem !important;
    border: 1px solid #dee2e6 !important;
}

span.user-name {
    color: #253544;
    font-size: 11px;
    font-weight: 600;
    user-select: none;
    letter-spacing: .5px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

span.user-role {
    color: #a7a1a1;
    font-size: 10px;
    font-weight: 400;
    user-select: none;
    letter-spacing: .45px;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

#component-design .nav.nav-tabs .component-design-pane:only-child {
    display: none !important;
}

#loginForm .form-checkbox {
    padding: 0;
    background: none;
}

form.submitting {
    padding: 10px;
    border: 1px solid rgba(0, 0, 0, .4);
    border-radius: 2px;
}

.form-checkbox {
    padding: 10px;
    cursor: pointer;
    align-items: center;
    margin: 0 !important;
    border-radius: .25rem;
    display: flex !important;
    background-color: #F0F0F0;
}

.form-checkbox input[type='checkbox'] {
    width: 15px;
    height: 15px;
    position: inherit !important;
    margin: 0 5px 0 0 !important;
    background: white;
    border-radius: 5px;
    border: 2px solid #555;
    margin-right: 20px;
}

@media all and (display-mode: fullscreen) {
    .ibox {
        display: flex;
        flex-direction: column;
    }

    .ibox-head {
        flex-shrink: 0;
    }

    .ibox-body {
        overflow-y: auto;
    }
}