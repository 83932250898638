.sheet {
	position: fixed;
	right: 0;
	bottom: 0;
	width: 40%;
	height: 100%;
	border-left: 1px solid #ddd;
	background-color: var(--p-surface, white);
	box-shadow: var(--p-modal-shadow,
			0 31px 41px 0 rgba(32, 42, 53, 0.2),
			0 2px 16px 0 rgba(32, 42, 54, 0.08));
	padding: 1em;
	z-index: 5;
	overflow-y: scroll;
}

.title {
	text-align: center;
}

.external-actions label {
	padding-right: 8px;
}

.btn-right {
	float: right;
}

.container {
	border: 1px #f2f2f2 solid;
}

@media all and (display-mode: fullscreen) {
	#process-design {
		padding: 20px;
		background: white;
	}
}