.contenteditable {
    position: relative;
    padding: 5px 10px;
    background-color: #eee;
    border-radius: 2px;
    border: 1px solid #aaa;
}

.contenteditable code {
    font-size: .875rem;
}

.contenteditable pre {
    padding: 0;
    margin: 5px 0;
    border: none;
    background: #eee;
    line-height: 22px;
    font-size: 13px;
}

.contenteditable .copy-to-clipboard {
    position: absolute;
    top: 5px;
    right: 10px;
}