.notification-container {
    font-size: 14px;
    position: fixed;
    z-index: 1;
}
.close-btn{

}
.top-right {
    top: 12px;
    right: 12px;
    transition: transform .6s ease-in-out;
    animation: toast-in-right .7s;
}