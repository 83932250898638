.see-more {
    line-height: 20px;
}

.see-more.collapsed {
    overflow-y: hidden !important;
}

.see-more-span:hover {
    text-decoration: underline;
}