.InputGroup {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
}
  
.InputElement .Right {
    padding: 0 25px 0 10px;
}
  
.InputElement .Left {
    padding: 0 10px 0 25px;
}
  
.InputElement .Both {
    padding: 0 25px 0 25px;
}
  
.InputElement {
    width: 100%;
    height: 31px;
    outline: none;
    display: flex;
    font-size: 15px;
    appearance: none;
    color: #293742;
    line-height: 30px;
    border-radius: 3px;
    position: relative;
    align-items: center;
    background: #ffffff;
    vertical-align: middle;
    box-sizing: border-box;
    padding: 0 25px 0 10px;
    border: solid 1px #ced9e0;
    font-family: "Montserrat", sans-serif;
}
  
.iconRight {
    right: 0;
    height: 100%;
    display: flex;
    padding: 0.5rem;
    font-size: 13px;
    color: #adb5bd;
    position: absolute;
    align-items: center;
}
  