.App {
	font-family: sans-serif;
	text-align: center;
}

.right-align {
	float: right;
}

.react-flow {
	font-family: sans-serif;
	width: 100%;
	height: 100vh;
}

.react-flow__node a {
	font-weight: 700;
	color: #111;
}
.react-flow__handle {
	height: 10px;
	width: 10px;
}

.react-flow__node {
	/* color: #aaa; */
	/* font-size: 10px; */
	/* font-weight: bold; */
	/* text-transform: uppercase; */
}

.react-flow__node.dark-node {
	background: #0041d0;
	color: #f8f8f8;
}

.react-flow__node-componentNode,
.react-flow__node-conditionNode {
	padding: 10px;
	border-radius: 3px;
	width: 130px;
	/* text-align: center; */
	background: #fff;
	border-color: #0041d0;
	border-width: 1px;
	border-style: solid;
}
.react-flow__node-componentNode {
	padding: 0 !important;
}
.react-flow__node-conditionNode {
	width: 300px;
}

.react-flow__node-componentNode.selectable.selected,
.react-flow__node-conditionNode.selectable.selected,
.react-flow__node-conditionNode.selectable.selected:hover
	.react-flow__node-componentNode.selectable.selected:hover {
	box-shadow: 0 0 0 0.5px #1a192b;
}

.react-flow__node-conditionNode.selectable:hover,
.react-flow__node-componentNode.selectable:hover {
	box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.08);
}

.react-flow__node-conditionNode .react-flow__handle,
.react-flow__node-componentNode .react-flow__handle {
	background: #0041d0;
}

.react-flow__node-conditionNode .react-flow__label,
.react-flow__node-startNode .react-flow__label,
.react-flow__node-endNode .react-flow__label {
	color: #aaa;
	font-size: 12px;
	font-weight: bold;
	text-transform: uppercase;
	text-align: center;
	margin-bottom: 8px;
}
.react-flow__node-componentNode .react-flow__label {
	color: #fff;
	font-size: 12px;
	font-weight: bold;
	text-transform: uppercase;
	text-align: center;
	margin-bottom: 8px;
}
.react-flow__node-startNode .react-flow__label,
.react-flow__node-endNode .react-flow__label {
	line-height: 50px;
	color: #fff;
}

.react-flow__node-selectorNode {
	font-size: 12px;
	background: #f0f2f3;
	border: 1px solid 555;
	border-radius: 5px;
	text-align: center;
}

.react-flow__node-output {
	font-size: 12px;
	background: #fff;
	border: 1px solid 555;
	border-radius: 5px;
	text-align: center;
	color: #f29b97;
	border: 1px #f29b97 solid;
}

.react-flow__node-selectorNode .react-flow__handle {
	border-color: #f0f2f3;
}

/* .react-flow__edge-textbg {
  fill: #0041d0;
  border-radius: 50px;
} */

.Polaris-ResourceItem__ListItem + .Polaris-ResourceItem__ListItem {
	border-top: none;
}
.default-node {
	padding: 10px;
	border-radius: 3px;
	width: 100px;
	/* text-align: center; */
	background: #fff;
	border-color: #0041d0;
	border-width: 1px;
	border-style: solid;
	margin-top: 3em;
}

.node-label {
	color: #aaa;
	font-size: 12px;
	font-weight: bold;
	text-transform: uppercase;
	text-align: center;
	margin-bottom: 8px;
}
.react-flow__node-startNode {
	background: lightblue;
	border-radius: 50%;
	width: 50px;
	height: 50px;
}

.react-flow__node-endNode {
	background: #f29b97;
	border-radius: 50%;
	width: 50px;
	height: 50px;
}
.react-flow__node-componentNode {
	background: #c0aef4;
	color: #fff;
}
.red-btn {
	color: red;
	padding: 0;
}

.edgebutton {
	width: 20px;
	height: 20px;
	background: #eee;
	border: 1px solid #fff;
	cursor: pointer;
	border-radius: 50%;
	font-size: 12px;
	line-height: 1;
}

.edgebutton:hover {
	box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.08);
}

.edgebutton-foreignobject div {
	background: transparent;
	width: 40px;
	height: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 40px;
}

.vertical-line {
	border-right: solid #f2f2f2;
	height: 34px;
}
